.main {
	flex-grow: 1; }

.hero {
	position: relative;
	text-align: left;
	padding: 155px 0;
	@include r($md) {
		text-align: center; }
	@include r($xs) {
		padding: 80px 0; }
	@include r($mob) {
		padding: 72px 0 0; }
	h1 {
		font-size: 50px;
		line-height: 1.2;
		@include r($xs) {
			@include fluidFontSize(48px, 56px, 375px, 1280px);
			margin-bottom: 16px; } }
	p {
		max-width: 600px;
		margin-bottom: 55px;
		margin-top: 10px;
		line-height: 1.4;
		@include r($md) {
			max-width: 100%; }
		@include r($mob) {
			font-size: 14px;
			line-height: 1.7;
			padding: 0 28px;
			margin-bottom: 40px; } }
	&__btn {
		@include r($md) {
			margin-bottom: 40px; }
		.btn {
			@include r($xs) {
				width: 276px; } } }
	&__img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(22%, -55%);
		@include r($lg) {
			max-width: 400px;
			transform: translate(28%, -55%); }
		@include r($md) {
			position: static;
			transform: none; }
		@include r($xs) {
			max-width: 81%; }
		@include r($mob) {
			display: none; } } }

.section {
	padding: 80px 0;
	text-align: center;
	@include r($xs) {
		padding: 40px 0; } }

.icon {
	&-items {
		margin: 80px 0 -40px;
		@include r($xs) {
			margin: 40px 0 -25px; } }
	&-item {
		margin-bottom: 40px;
		@include r($xs) {
			margin-bottom: 25px; }
		&__image {
			margin-bottom: 25px;
			@include r($xs) {
				span {
					width: 40px;
					height: 40px; } } } } }

.why {
	&-hero {
		margin-bottom: 80px;
		@include r($xs) {
			margin-bottom: 40px; }
		&__img {
			margin-bottom: 40px; } }
	&-items {
		margin-bottom: -30px; }
	&-item {
		margin-bottom: 30px;
		&__img {
			margin-bottom: 40px;
			@include r($xs) {
				width: 150px;
				margin: 0 auto 25px; } }
		h5 {
			font-size: 22px;
			@include r($xs) {
				margin-bottom: 20px; } }
		p {
			letter-spacing: 0.02em;
			padding: 0 5px;
			@include fluidFontSize(14px, 16px, 375px, 1280px);
			@include r($xs) {
				line-height: 1.75; } } } }

.how {
	&-footer {
		margin-top: 80px;
		text-align: center;
		@include r($xs) {
			margin-top: 40px; } }
	&-tabs {
		margin-top: 80px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		&__content {
			width: 61%;
			@include r($md) {
				width: 100%;
				margin-bottom: 40px; } }
		&__btns {
			width: 39%;
			padding-left: 64px;
			@include r($lg) {
				padding-left: 32px; }
			@include r($md) {
				width: 100%;
				padding-left: 0; } }
		&__btn {
			display: flex;
			padding: 14px 20px 14px 16px;
			text-align: left;
			background: #FFFFFF;
			border: 1px solid transparent;
			border-radius: 6px;
			transition: $tr;
			cursor: pointer;
			&.active {
				border-color: $lightgray;
				box-shadow: 0px 8px 16px rgba(114, 123, 144, 0.04), 0px 4px 8px rgba(114, 123, 144, 0.08); }
			&-icon {
				width: 80px; }
			&-content {
				width: calc(100% - 80px);
				padding-left: 22px;
				@include r($xs) {
					padding-left: 15px; }
				h6 {
					margin-bottom: 10px; } } } } }

.tariffs {
	&-items {
		margin: 125px 0 -75px;
		@include r($xs) {
			margin: 85px 0 -75px; } }
	&-item {
		background-color: $bg;
		border-radius: 6px;
		position: relative;
		text-align: left;
		padding: 80px 40px 40px;
		margin-bottom: 75px;
		@include r($xs) {
			padding: 53px 24px 24px; }
		h3 {
			margin-bottom: 40px;
			@include r($xs) {
				margin-bottom: 25px; } }
		h6 {
			margin-bottom: 20px; }
		ul {
			margin-bottom: 40px;
			@include r($xs) {
				margin-bottom: 25px; }
			li {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0; } } }
		.btn {
			width: 100%; }
		&__icon {
			position: absolute;
			left: 20px;
			top: -45px;
			@include r($xs) {
				left: 0;
				right: 0;
				text-align: center;
				span {
					width: 115px; } } } } }

.faq {
	text-align: left;
	@include r($xs) {
		padding-bottom: 0; }
	h3 {
		margin-bottom: 40px; }
	&-items {
		margin-bottom: 166px;
		@include r($xs) {
			margin-bottom: 80px; } }
	&-item {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0; }
		p {
			@include fluidFontSize(14px, 16px, 375px, 1280px); } } }

.promo {
	background-color: $primary;
	border-radius: 12px;
	position: relative;
	width: 100%;
	color: #fff;
	text-align: center;
	padding: 80px 40px 40px;
	@include r($xs) {
		margin: 0 -24px;
		width: calc(100% + 48px);
		border-radius: 0;
		padding: 50px 24px 26px; }
	&-img {
		position: absolute;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		top: -55px;
		@include r($xs) {
			top: -48px;
			span {
				width: 115px; } } }
	&-title {
		margin-bottom: 45px;
		letter-spacing: normal;
		@include r($xs) {
			margin-bottom: 25px; } }
	.btn {
		width: 100%; }
	.label {
		opacity: 0.4; } }


.about {
	padding: 80px 0 115px;
	@include r($xs) {
		padding: 40px 0 0; }
	h2 {
		margin-bottom: 65px;
		@include r($xs) {
			margin-bottom: 40px; } }
	&-hero {
		margin-bottom: 70px;
		@include r($xs) {
			nb: 40; } }
	&-items {
		margin-top: 40px; }
	&-content {
		margin-bottom: 110px;
		@include r($xs) {
			margin-bottom: 80px; } } }

.terms {
	padding: 80px 0;
	@include r($xs) {
		padding: 40px 0; }
	&-tabs {
		&__btns {
			display: inline-flex;
			border: 1px solid rgba(31, 35, 44, 0.12);
			border-radius: 4px;
			overflow: hidden;
			margin-bottom: 60px;
			@include r($xs) {
				margin-bottom: 40px; } }
		&__btn {
			display: inline-flex;
			align-items: center;
			padding: 0 16px;
			cursor: pointer;
			height: 36px;
			color: $gray;
			font-size: 12px;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			font-family: $i-medium;
			background-color: #fff;
			&.active {
				background-color: $secondary;
				color: #fff; } }
		&__item {
			h2 {
				@include r(400px) {
					font-size: 26px; } }
			&-descr {
				margin-bottom: 40px;
				span {
					display: block;
					margin-bottom: 5px; } } } }
	&-list {
		counter-reset: termsList;
		color: $dark;
		line-height: 1.65;
		&__title {
			@include fluidFontSize(18px, 22px, 375px, 1280px);
			font-family: $i-semibold;
			line-height: 1.4;
			margin-bottom: 10px;
			&:before {
				content: counters(termsList, ".") ". ";
				@include fluidFontSize(18px, 22px, 375px, 1280px);
				font-family: $i-semibold;
				color: $dark!important; } }
		&>li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 25px!important;
			&:before {
				content: none!important; }
			&>ol {
				&>li {
					padding-left: 104px;
					@include r($xs) {
						padding-left: 50px; }
					&:before {
						left: 0; } } } }
		ol {
			counter-reset: termsList;
			margin-top: 15px; }
		li {
			@include fluidFontSize(14px, 16px, 375px, 1280px);
			letter-spacing: 0.02em;
			font-feature-settings: 'kern' off;
			font-family: $i-regular;
			counter-increment: termsList;
			position: relative;
			margin-bottom: 15px;
			&:before {
				content: counters(termsList, ".") ". ";
				color: $gray;
				margin-right: 5px;
				position: absolute;
				left: -104px;
				top: 0;
				display: block;
				@include r($xs) {
					left: -50px; } } }
		ul {
			margin-top: 7px;
			li {
				margin-bottom: 0;
				counter-increment: none;
				&:before {
					content: '•';
					position: static;
					display: inline;
					color: $dark; } } } } }

.tabs {
	&-item {
		display: none;
		&.active {
			display: block; } } }
