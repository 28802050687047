.blog {
    padding: 16px 0 115px;

    .breadcrumbs {
        margin-bottom: 30px; }

    &__title {
        margin-bottom: 32px;
        @media (max-width: 767px) {
            margin-bottom: 16px; }
        .grid-col--xs-6.grid-col--md-4 {
            display: flex;
            align-items: flex-end; }
        h1 {
            font-family: $i-semibold;
            @include fluidFontSize(32px, 56px, 375px, 1280px); } }

    &-search {
        h6 {
            font-family: $i-semibold;
            margin-bottom: 8px; }
        &__form {
            display: flex;
            input {
                width: 100%;
                flex-grow: 1;
                margin-right: 16px;
                border: 2px solid $lightgray;
                border-radius: 4px;
                padding: 0 28px;
                font-size: 14px; }
            .btn {
                flex-shrink: 0; } } }

    &__items {
        margin-bottom: 8px; }

    &-item {
        display: block;
        margin-bottom: 40px;
        border-bottom: none;
        &:last-child {
            margin-bottom: 0; }
        &__img {
            position: relative;
            width: 100%;
            border-radius: 6px;
            overflow: hidden;
            padding-bottom: 67.363%;
            margin-bottom: 16px;
            &::before {
                content: '';
                display: block;
                background-color: rgba($secondary, .25);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                transition: opacity .3s ease;
                z-index: 2; }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1; }
            .btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                z-index: 3; } }
        .categories {
            font-size: 12px;
            line-height: 1.66;
            color: $gray;
            text-transform: uppercase;
            margin-bottom: 16px; }
        h5 {
            line-height: 1.45;
            color: $dark;
            letter-spacing: 0;
            margin-bottom: 16px;
            transition: color .3s ease; }
        p {
            line-height: 1.5;
            color: $darkgray; }
        &:hover {
            .blog-item__img {
                &::before, .btn {
                    opacity: 1; } }
            h5 {
                color: $secondary; } } } }

.blog-post {
    padding: 16px 0 0;
    @media (max-width: 767px) {
        padding-bottom: 40px; }

    .breadcrumbs {
        margin-bottom: 40px; }

    &__title {
        margin-bottom: 0;
        .categories {
            font-size: 12px;
            line-height: 1.66;
            color: $gray;
            text-transform: uppercase;
            margin-bottom: 8px; }
        h1 {
            font-family: $i-semibold;
            line-height: 1.25;
            @include fluidFontSize(24px, 32px, 375px, 1280px); } }

    &__content {
        p, ul, ol {
            font-size: 14px;
            line-height: 1.5;
            color: $darkgray;
            letter-spacing: 0.02em; } }

    &__img {
        position: relative;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        padding-bottom: 37.3%;
        margin-bottom: 24px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__text {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0; }
        h5 {
            margin-bottom: 16px; }
        p {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0; } }
        ul {
            li {
                position: relative;
                padding-left: 20px;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0; }
                &::before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    background-color: $secondary;
                    position: absolute;
                    top: 6.5px;
                    left: 0; } } } }
    &__useful {
        display: flex;
        border-top: 1px solid $lightgray;
        line-height: 1.4;
        padding-top: 16px;
        .question {
            font-family: $i-semibold;
            color: $darkgray;
            margin-right: 12px; }
        .answer {
            display: flex;
            a {
                font-family: $i-semibold;
                color: $secondary;
                margin-right: 12px;
                &:last-child {
                    color: $gray;
                    margin-right: 0; } } } }

    &__sidebar {
        .recent-posts {
            border-top: 1px solid $lightgray;
            padding-top: 24px;
            margin-top: 24px;
            h6 {
                margin-bottom: 16px; }
            &__links {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                a {
                    position: relative;
                    margin-left: 20px;
                    font-family: $i-medium;
                    font-size: 16px;
                    line-height: 1.625;
                    color: $gray;
                    letter-spacing: 0.02em;
                    font-feature-settings: 'ss01' on, 'salt' on, 'ordn' on, 'kern' off;
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 0; }
                    &::before {
                        content: '';
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 100%;
                        background-color: $gray;
                        position: absolute;
                        top: 8.5px;
                        left: -20px; }
                    &:hover {
                        color: $secondary; } } } }

        .custom-posts {
            border-top: 1px solid $lightgray;
            padding-top: 24px;
            margin-top: 24px;
            h6 {
                margin-bottom: 16px; }
            .c-post {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
                border-bottom: none;
                &:last-child {
                    margin-bottom: 0; }
                &__img {
                    position: relative;
                    flex-shrink: 0;
                    width: calc(50% - 16px);
                    border-radius: 6px;
                    overflow: hidden;
                    padding-bottom: 43.2%;
                    &::before {
                        content: '';
                        display: block;
                        background-color: rgba(#fff, .5);
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        transition: opacity .3s ease;
                        z-index: 2; }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        z-index: 1; }
                    .btn {
                        white-space: nowrap;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        opacity: 0;
                        z-index: 3; } }
                &__text {
                    flex-shrink: 0;
                    width: calc(50% - 16px);
                    .categories {
                        font-size: 12px;
                        line-height: 1.66;
                        color: $gray;
                        text-transform: uppercase;
                        margin-bottom: 8px; }
                    h6 {
                        color: $dark;
                        line-height: 1.33;
                        margin: 0;
                        transition: color .3s ease; } }
                &:hover {
                    .c-post__img {
                        &::before, .btn {
                            opacity: 1; } }
                    h6 {
                        color: $secondary; } } } } } }
