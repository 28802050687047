$breakpoints: (mob: 320px, xs: $xs, md: $md, lg: $lg, xl: $xl);

@mixin break($size) {
    @media (min-width: map-get($breakpoints, $size)) {
        @content; } }

$items: 12;

.grid {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -16px -32px;
    &.jcc {
        justify-content: center; } }
[class^=grid-col--] {
    flex: 0 0 100%;
    width: 100%; }

@each $key, $value in $breakpoints {
    @for $i from 1 through $items {
        .grid-col--#{$key}-#{$i} {
            padding: 0 16px 32px;
            @include break($key) {
                flex: 0 0 #{$i / $items * 100%};
                width: #{$i / $items * 100%}; } } } }
