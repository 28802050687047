@import "./normalize";
@import "./vars";
@import "./mixins";
@import "./fonts";
@import "./grid";
@import "./sprite_generated";

// From libs:
@import "../../libs/select2/dist/css/select2.min";
@import "../../libs/ion-rangeslider/css/ion.rangeSlider.min";
@import "../../libs/simplebar/dist/simplebar.min";
@import "../../libs/air-datepicker/dist/css/datepicker.min";

* {
    box-sizing: border-box; }

html {
    overflow-x: hidden; }

body {
    display: flex;
    flex-direction: column;
    background: #fff;
    color: $dark;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: $ls;
    font-family: $i-regular, "Helvetica", Arial, sans-serif;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    @include fluidFontSize(12px, 16px, 375px, 1280px); }

[class^=svg-image-] {
    display: inline-block;
    vertical-align: top; }
img, svg {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    vertical-align: top; }

a {
    color: $primary;
    text-decoration: none;
    transition: $tr;
    &.blue {
        color: $secondary;
        &:hover {
            color: #1B90DC; } }
    &.dark {
        color: $darkgray;
        &:hover {
            color: $secondary; } }
    &.gray {
        color: $gray;
        &:hover {
            color: $secondary; } }
    &.orange {
        color: $orange;
        &:hover {
            color: $primary; } } }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
input[type=number] {
    -moz-appearance: textfield; }
input, select, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 100%; }

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
    font-size: 16px; }

table {
    width: 100%; }

.hidden-xs {
    @include r($xs) {
        display: none!important; } }

.container {
    width: 100%;
    max-width: 1280px;
    padding: 0 32px;
    margin: 0 auto;
    @include r($xs) {
        padding: 0 24px; } }

.list {
    &--line {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0; }
        li {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0; }
            &:before {
                content: "— "; } } }
    &--circle {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0; }
        li {
            padding-left: 36px;
            position: relative;
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0; }
            &:before {
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $secondary;
                position: absolute;
                top: 8px;
                left: 14px; } } } }



h1 {
    font-size: 80px;
    line-height: 1;
    font-family: $i-medium;
    letter-spacing: -0.04em; }

h2, h3, h4, h5, h6 {
    line-height: 1.15;
    font-family: $i-semibold;
    letter-spacing: -0.02em;
    margin-bottom: 20px;
    @include r($xs) {
        line-height: 1.25; } }

h2 {
    font-size: 56px;
    @include fluidFontSize(32px, 56px, 375px, 1280px); }

h3 {
    font-size: 40px;
    @include fluidFontSize(32px, 40px, 375px, 1280px); }

h4 {
    font-size: 32px; }

h5 {
    font-size: 22px;
    margin-bottom: 15px;
    @include fluidFontSize(18px, 22px, 375px, 1280px); }

h6 {
    font-size: 18px;
    margin-bottom: 14px;
    @include fluidFontSize(16px, 18px, 375px, 1280px); }

p {
    font-feature-settings: 'kern' off;
    letter-spacing: normal;
    margin-bottom: 25px;
    &:last-child {
        margin-bottom: 0; } }

.main {
    padding-top: 100px;
    @include r($xs) {
        padding-top: 50px; } }

.gray {
    color: $gray; }

.primary {
    color: $primary; }

.secondary {
    color: $secondary; }

.orange {
    color: $orange; }

.green {
    color: $green; }

.error, .has-error {
    color: $error;
    label, input, select, textarea, .field {
        color: $error; } }

.hidden {
    display: none; }

.text {
    &-lg {
        @include fluidFontSize(16px, 20px, 375px, 1280px); }
    &-md {
        font-family: $i-medium; }
    &-sm {
        @include fluidFontSize(12px, 14px, 375px, 1280px); } }

.caption {
    font-size: 12px;
    font-feature-settings: 'kern' off; }

.label {
    font-size: 12px;
    letter-spacing: 0.08em;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-feature-settings: 'ss01' on, 'salt' on, 'ordn' on, 'kern' off;
    @include r($xs) {
        margin-bottom: 3px; } }

.bold {
    font-family: $i-semibold; }

.btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $secondary;
    color: #fff;
    border-radius: 4px;
    line-height: 1.3;
    outline: 0;
    border: 0;
    padding: 14px 28px;
    font-size: 16px;
    transition: $tr;
    &--sm {
        padding: 11px 16px;
        font-size: 14px; }
    &--with-margins {
        margin-right: 3px;
        margin-bottom: 3px; }
    &:hover {
        background-color: #60BEFA; }
    &:active {
        background-color: #2D95DA; }
    &.disabled {
        background-color: $bg;
        color: $gray;
        pointer-events: none; }
    &--white {
        background-color: #fff;
        color: $orange;
        box-shadow: 0px 1px 6px rgba(60, 138, 189, 0.2);
        &:hover {
            box-shadow: 1px 3px 8px rgba(60, 138, 189, 0.2);
            background-color: #fff;
            color: inherit; } }
    &--outline {
        background: none;
        border: 1px solid $secondary;
        color: $secondary;
        &:hover {
            background-color: $secondary;
            color: #fff; } } }

.field {
    border: 2px solid rgba(255, 255, 255, 0.24);
    border-radius: 4px;
    height: 40px;
    padding: 0 26px;
    background-color: transparent;
    width: 100%;
    margin-bottom: 25px;
    color: #fff;
    outline: 0;
    font-size: 16px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.56); }
    &-password {
        position: relative;
        input {
            margin: 0; }
        &__icon {
            position: absolute;
            z-index: 2;
            right: 16px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                width: 24px;
                height: 24px; }
            &.active {
                .field-password__icon {
                    &--default {
                        opacity: 0; }
                    &--active {
                        opacity: 1; } } }
            &--default {
                display: flex;
                width: 24px;
                height: 24px;
                transition: $tr;
                opacity: 1; }
            &--active {
                display: flex;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 0;
                left: 0;
                transition: $tr;
                opacity: 0; } } } }
.textarea {
    height: 104px;
    resize: none; }


.dropdown {
    position: relative;
    &.active {
        .dropdown-box {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            z-index: 1; } }
    &-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 2;
        transition: $tr; }
    &-box {
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0px 6px 10px rgba(0, 5, 58, 0.1);
        width: 180px;
        padding: 8px 16px;
        opacity: 0;
        transform: translateY(20px);
        visibility: hidden;
        z-index: -1;
        transition: $tr; }
    &-list {
        &__item {
            border-bottom: 1px solid rgba(228, 233, 237, .6);
            &:last-child {
                border-bottom: 0; } }
        &__link {
            width: 100%;
            padding: 7px 0;
            display: block; } } }

.select2 {
    &-dropdown {
        border-color: $gray;
        background-color: $bg;
        &--time {
            .select2-results__option {
                font-family: $i-medium;
                color: $darkgray; } } }
    &-results {
        padding: 2px 0;
        &__options {
            padding: 10px 0; }
        &__option {
            padding: 4px 12px;
            font-size: 14px;
            color: $gray;
            &[aria-selected=true] {
                background-color: transparent!important;
                color: $gray!important; }
            &--highlighted[aria-selected] {
                background-color: transparent!important;
                color: $secondary!important; } } }
    &-container {
        &--open {
            .select2-selection {
                &__arrow {
                    transform: rotate(180deg); } } }
        &--focus, &--open {
            .select2-selection {
                border-color: $gray!important; } }
        .select2-selection {
            height: 40px;
            border: 1px solid $lightgray;
            outline: 0;
            .select2-selection {
                &__arrow {
                    width: 16px;
                    height: 16px;
                    background-image: url("../images/select-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 12px;
                    b {
                        display: none; } }
                &__rendered {
                    height: 100%;
                    line-height: 40px;
                    padding: 0 14px;
                    color: $gray; }
                &__placeholder {
                    color: rgba(114, 123, 144, .8); } } } }
    &-search {
        &__field {
            outline: none; } } }

.c-chart {
    position: relative;
    width: 72px;
    height: 72px;
    &__value {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 48px;
        height: 48px;
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(231, 240, 244, 0.7);
        border-radius: 50%;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center; }
    &__circle {
        width: 100%;
        height: 100%;
        transform: rotate(-90deg); } }

.rangeslider {
    opacity: 0;
    transition: $tr;
    &.active {
        opacity: 1; } }
.irs {
    &--flat {
        &.irs-with-grid {
            height: 35px; }
        .irs {
            &-line {
                background-color: #727B90;
                opacity: 0.4;
                height: 4px;
                border-radius: 8px;
                @include r($xs) {
                    height: 6px;
                    top: 30px; } }
            &-bar {
                background-color: #727B90;
                height: 4px;
                @include r($xs) {
                    height: 6px;
                    top: 30px; } }
            &-handle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 3px solid $primary;
                background-color: #fff;
                top: 21px;
                cursor: pointer;
                @include r($xs) {
                    height: 24px;
                    width: 24px;
                    border-width: 6px; }
                i {
                    display: none!important; } }
            &-grid {
                height: 14px;
                top: -2px;
                @include r($xs) {
                    top: 0; }
                &-pol {
                    display: none; }
                &-text {
                    font-size: 14px;
                    line-height: 1;
                    font-family: $i-medium;
                    color: $dark; } } } } }

.radio {
    &-btns {
        &__items {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: -12px; } }
    &-btn {
        margin: 0 12px 12px 0;
        &:last-child {
            margin-right: 0; }
        &.disabled {
            pointer-events: none; }
        &__input {
            display: none;
            &:checked {
                &+.radio-btn__view {
                    background-color: $secondary;
                    color: #fff;
                    box-shadow: none; } } }
        &__view {
            min-width: 130px;
            height: 38px;
            padding: 0 20px;
            font-size: 16px;
            font-family: $i-semibold;
            background-color: #fff;
            box-shadow: 0px 1px 6px rgba(60, 138, 189, 0.2);
            color: $dark;
            @include r($xs) {
                min-width: 120px; }
            &:hover {
                box-shadow: 1px 3px 8px rgba(60, 138, 189, 0.2);
                color: $dark;
                background-color: #fff; }
            &:active {
                box-shadow: 0px 0px 3px 1px rgba(60, 138, 189, 0.1);
                background-color: #fff; } } } }

.simplebar {
    &-track {
        background-color: $lightgray;
        border-radius: 12px;
        width: 4px!important; }
    &-scrollbar {
        background-color: $darkgray;
        border-radius: 12px;
        width: 4px; }
    &-horizontal {
        height: 4px!important;
        width: 100%!important;
        .simplebar-scrollbar {
            height: 100%!important;
            top: 0!important; } } }

.addfile {
    &.done {
        .addfile-btn {
            display: none; } }
    &-btn {
        display: inline-flex;
        align-items: center;
        cursor: pointer; }
    &-icon {
        margin-right: 8px;
        flex: 0 0 24px; }
    &-text {
        font-family: $i-medium;
        color: $secondary; }
    &-items {
        display: flex;
        flex-wrap: wrap; }
    &-item {
        border-radius: 4px;
        border: 1px solid $lightgray;
        padding: 8px;
        position: relative;
        width: 128px;
        margin: 0 12px 12px 0;
        transition: $tr;
        &:hover {
            border-color: $secondary; }
        &__delete {
            position: absolute;
            right: 4px;
            top: 4px;
            cursor: pointer;
            width: 18px;
            height: 18px;
            &--default, &--hover {
                position: absolute;
                top: 0;
                left: 0;
                transition: $tr; }
            &--default {
                opacity: 1; }
            &--hover {
                opacity: 0; }
            &:hover {
                .addfile-item__delete {
                    &--default {
                        opacity: 0; }
                    &--hover {
                        opacity: 1; } } } }
        &__icon {
            margin-bottom: 4px; }
        &__name {
            font-size: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $darkgray; } } }

.datepicker {
    width: 360px;
    box-shadow: 2px 4px 15px rgba(63, 68, 128, 0.2);
    border-radius: 12px;
    border: 0;
    @include r($mob) {
        width: 274px; }
    &--pointer {
        display: none; }
    &--content {
        padding: 9px; }
    &--nav {
        padding: 9px;
        border: 0;
        justify-content: center;
        &-action {
            &:first-child {
                &:after {
                    transform: rotate(90deg); } }
            &:last-child {
                &:after {
                    transform: rotate(-90deg); } }
            &:after {
                content: '';
                width: 24px;
                height: 24px;
                background-image: url("../images/select-arrow.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center; }

            svg {
                display: none; } }
        &-title {
            font-size: 22px;
            font-family: $i-semibold;
            color: $dark;
            margin: 0 16px;
            i {
                color: $secondary; } }
        &- {} }
    &--days {
        &-names {
            margin: 0 0 24px; } }
    &--day {
        &-name {
            font-family: $i-medium;
            font-size: 14px;
            line-height: 1.4;
            color: $darkgray;
            text-transform: inherit;
            font-feature-settings: 'kern' off; } }
    &--cells {
        margin: -4px 0; }
    &--cell {
        border: 1px solid transparent;
        color: $gray;
        font-size: 14px;
        height: 24px;
        margin: 4px 9px;
        @include r($mob) {
            margin: 4px; }
        &:hover {
            background-color: $secondary;
            color: #fff; }
        &.-other-month-, &.-other-decade- {
            &:hover {
                color: #fff; } }
        &.-disabled- {
            background-color: $lightgray;
            color: $gray;
            border-radius: 0;
            &.-focus- {
                color: $gray!important; } }
        &.-current- {
            color: $gray;
            background-color: transparent;
            &:hover {
                background-color: $secondary;
                color: #fff;
                &:before {
                    background-color: #fff; } }
            &.-selected- {
                background-color: transparent;
                color: $gray;
                border: 1px solid $secondary;
                &:hover {
                    &:before {
                        background-color: $secondary; } } }
            &:before {
                content: '';
                width: 4px;
                height: 4px;
                background-color: $secondary;
                border-radius: 100%;
                position: absolute;
                top: 2px;
                right: 6px;
                @include r($mob) {
                    right: 2px; } } }
        &.-selected- {
            border: 1px solid $secondary;
            color: $gray;
            background-color: transparent;
            &.-focus- {
                background-color: transparent; } }
        &-day {
            color: $gray;
            font-size: 14px;
            height: 24px;
            margin: 4px 9px;
            width: calc(14.28571% - 18px);
            @include r($mob) {
                margin: 4px;
                width: calc(14.28571% - 8px); }
            &.-disabled- {
                width: 14.28571%;
                margin: 4px 0; } }
        &-day {
            width: calc(14.28571% - 18px);
            @include r($mob) {
                width: calc(14.28571% - 8px); }
            &.-disabled- {
                width: 14.28571%;
                margin: 4px 0; }
            &:first-child, &:nth-child(7n + 1) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px; }
            &:last-child, &:nth-child(7n), &.-lastDisabled- {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; } }
        &-month {
            width: calc(33.333% - 18px);
            height: calc(25% - 8px);
            @include r($mob) {
                width: calc(33.333% - 8px); }
            &.-disabled- {
                width: 33.333%;
                margin: 4px 0; }
            &:first-child, &:nth-child(3n + 1) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px; }
            &:last-child, &:nth-child(3n), &.-lastDisabled- {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; } }
        &-year {
            width: calc(25% - 18px);
            height: calc(33.333% - 8px);
            @include r($mob) {
                width: calc(25% - 8px); }
            &.-disabled- {
                width: 25%;
                margin: 4px 0; }
            &:first-child, &:nth-child(4n + 1) {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px; }
            &:last-child, &:nth-child(4n), &.-lastDisabled- {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; } } }
    &--buttons {
        padding: 9px;
        border: 0; }
    &--button {
        font-size: 14px;
        font-family: $i-medium;
        &:first-child {
            padding-left: 18px;
            color: $darkgray;
            justify-content: flex-start;
            &:before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-right: 8px;
                background-color: $secondary; } }
        &:last-child {
            justify-content: flex-end;
            padding-right: 18px;
            color: $secondary; } } }

.popup {
    position: fixed;
    z-index: -1;
    visibility: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.active {
        z-index: 30;
        visibility: visible; }
    &.animate {
        .popup {
            &-overlay {
                opacity: 1; }
            &-content {
                opacity: 1;
                transform: translateY(0);
                transition-delay: .15s;
                @include r($xs) {
                    transition-delay: 0s;
                    transform: none; } } } }
    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
        background-color: rgba(0, 0, 0, .7);
        cursor: pointer;
        transition: $tr;
        @include r($xs) {
            display: none; } }
    &-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        @include r($xs) {
            padding: 0; } }
    &-content {
        max-width: 360px;
        width: 100%;
        background-color: #fff;
        border-radius: 12px;
        box-shadow: 2px 4px 15px rgba(63, 68, 128, 0.2);
        padding: 48px 32px 40px;
        position: relative;
        z-index: 2;
        opacity: 0;
        font-family: $i-medium;
        transform: translateY(-40px);
        transition: $tr;
        @include r($xs) {
            max-width: 100%;
            height: 100%;
            border-radius: 0;
            background-color: $bg;
            padding: 100px 16px 70px;
            transform: none;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch; } }
    &-close {
        position: absolute;
        right: 16px;
        top: 16px;
        span {
            width: 24px;
            height: 24px; }
        @include r($xs) {
            display: none; } }
    &-label {
        margin-bottom: 12px;
        &:after {
            content: attr(data-error);
            color: $error;
            font-size: 0.8em; }
        &__head {
            margin-bottom: 8px;
            display: flex;
            &-text {
                margin-right: 8px;
                font-family: $i-medium; } } }
    &-submit {
        margin-top: 32px;
        @include r($xs) {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0; }
        .btn {
            width: 100%;
            @include r($xs) {
                border-radius: 0; } } }
    &-hint {
        margin-top: 12px;
        color: $gray;
        font-family: $i-medium; } }
.pagination {
    padding: 0;
    font-weight: bold;
    text-align: center;
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    >li {
        display: inline-block;
        margin: 0 3px;
        text-align: center;
        border-radius: 4px;
        border: solid 1px $secondary;
        color: $secondary;
        min-width: 38px;
        line-height: 20px;
        &:first-child {
            margin-left: 0; }
        &:last-child {
            margin-right: 0; }
        &.disabled {
            opacity: .4;
            color: $gray;
            border-color: $gray; }
        &.prev {
            float: left; }
        &.next {
            float: right; }
        &.active {
            >a {
                background-color: $secondary;
                color: #fff; } }
        >a, >span {
            display: block;
            padding: 8px 13px; }
        >a {
            color: $secondary;
            &:hover {
                opacity: 0.7; } } } }

input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &+label {
        user-select: none;
        line-height: 23px;
        position: relative;
        padding-left: 26px;
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #d9dde8;
            border-radius: 0.25em;
            margin-right: 0.5em;
            background-color: #fff;
            font-size: 19px;
            line-height: 16px;
            font-weight: bold;
            padding: 0 1px; } }
    &:checked+label::before {
        content: '\2713'; } }

.tooltip {
    display: inline-flex;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;
    text-align: center;
    &-icon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &--default, &--active {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $tr; }
        &--default {
            opacity: 1; }
        &--active {
            opacity: 0; } }

    &__text {
        background-color: $secondary;
        width: 230px;
        padding: 9px 12px;
        font-size: 12px;
        color: #fff;
        text-align: left;
        border-radius: 5px;
        position: absolute;
        visibility: hidden;
        right: 35px;
        opacity: 0;
        transition: all .4s ease;
        top: 50%;
        transform: translateY(-50%);
        white-space: pre-line;
        &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent $secondary; } }
    &--right {
        .tooltip__text {
            width: 260px;
            top: 9px;
            left: 35px;
            &::after {
                content: " ";
                position: absolute;
                top: 50%;
                right: 100%;
                left: unset;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent $secondary transparent transparent; } } }
    &--top {
        .tooltip__text {
            width: 300px;
            bottom: 100%;
            left: 50%;
            top: unset;
            transform: none;
            margin-left: -150px;
            margin-bottom: 10px;
            &::after {
                content: " ";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                margin-top: 0;
                border-width: 5px;
                border-style: solid;
                border-color: $secondary transparent transparent transparent; } } }
    &:hover {
        .tooltip-icon {
            &--default {
                opacity: 0; }
            &--active {
                opacity: 1; } }
        .tooltip__text {
            opacity: 1;
            visibility: visible;
            z-index: 100; } } }

.p-methods {
    background-color: #fff;
    border: 1px solid $secondary;
    border-radius: 4px;
    padding: 32px 24px;
    @media (max-width: 499px) {
        padding: 20px 16px; }

    h6 {
        font-size: 18px;
        line-height: 1.4;
        margin-bottom: 24px; }

    &__row {
        border-bottom: 1px solid rgba(46,169,249, .4);
        padding-bottom: 24px;
        margin-bottom: 24px;
        @media (max-width: 499px) {
            padding-bottom: 16px; }
        .row-title {
            font-family: $i-medium;
            font-size: 14px;
            line-height: 1.4;
            color: $darkgray;
            margin-bottom: 8px; }
        .field {
            background-color: #fff;
            border: 1px solid #E4E9ED;
            font-size: 14px;
            line-height: normal;
            color: $dark;
            padding: 0 12px;
            margin: 0; }
        .total-value {
            font-family: $i-semibold;
            font-size: 18px;
            line-height: 1.33; } }

    &__items {
        display: flex;
        margin: 16px 0 8px; }

    &__item {
        position: relative;
        width: calc(25% - 12px);
        padding-bottom: 21.4%;
        margin-right: 16px;
        &:last-child {
            margin-right: 0; }
        input {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            &:checked + div {
                border-color: $secondary;
                box-shadow: none; } }
        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            box-shadow: 0px 2px 4px rgba(0,0,0, .08);
            border-radius: 4px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            transition: all .2s ease;
            span {
                flex-shrink: 0;
                &.svg-image-paypal {
                    width: 40%; }
                &.svg-image-visa {
                    width: 68%; }
                &.svg-image-mc-logo {
                    width: 54%; }
                &.svg-image-crypto {
                    width: 68%; } } } }

    .btn {
        width: 100%; } }

.breadcrumbs {
    font-family: $i-medium;
    font-size: 14px;
    line-height: 1.7;
    &__link {
        position: relative;
        color: $dark;
        margin-right: 28px;
        &:hover {
            color: $primary; }
        &::after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.9797 3.14645C5.78444 3.34171 5.78444 3.65829 5.9797 3.85355L10.1261 8L5.9797 12.1464C5.78444 12.3417 5.78444 12.6583 5.9797 12.8536C6.17496 13.0488 6.49154 13.0488 6.68681 12.8536L11.1868 8.35355C11.3821 8.15829 11.3821 7.84171 11.1868 7.64645L6.68681 3.14645C6.49154 2.95118 6.17496 2.95118 5.9797 3.14645Z' fill='%231F232C'/%3E%3C/svg%3E");
            position: absolute;
            top: 50%;
            right: -22px;
            transform: translateY(-50%); } }
    &__current {
        color: $secondary; } }
