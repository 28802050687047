.header {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transition: $tr;
    @include r($xs) {
        height: 50px; }
    &.fixed {
        background-color: #fff;
        box-shadow: 0 15px 45px -4px rgba(0,0,0,.2);
        .header {
            &-container {
                padding: 15px 0; } } }
    &-container {
        display: flex;
        align-items: center;
        padding: 30px 0;
        transition: $tr;
        @include r($xs) {
            padding: 15px 0; } }
    &-logo {
        margin-right: 40px;
        &__link {
            display: flex;
            align-items: center; } }
    &-menu {
        display: flex;
        align-items: center;
        width: calc(100% - 171px);
        @include r($xs) {
            display: none;
            position: fixed;
            top: 50px;
            left: 0;
            right: 0;
            width: 100%;
            height: auto;
            background-color: #fff;
            box-shadow: 0 15px 15px -4px rgba(0,0,0,.2);
            padding-bottom: 30px; }
        &__list {
            display: flex;
            align-items: center;
            @include r($xs) {
                display: block;
                text-align: center;
                margin-bottom: 20px; } }
        &__item {
            margin-right: 20px;
            @include r($xs) {
                margin: 0; }
            &:last-child {
                margin-right: 0; } }
        &__link {
            font-size: 14px;
            font-family: $i-medium;
            color: $gray;
            border-bottom: 1px solid transparent;
            @include r($xs) {
                padding: 5px 0;
                display: block;
                border: 0; }
            &:hover {
                color: $primary;
                border-color: $primary; }
            &.active {
                color: $dark;
                border: 0; } } }
    &-btn {
        margin-left: auto;
        @include r($xs) {
            text-align: center; } }
    &-burger {
        display: none;
        width: 24px;
        height: 21px;
        margin-left: auto;
        position: relative;
        cursor: pointer;
        @include r($xs) {
            display: block; }
        &.active {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(7px, 6px); }
                &:nth-child(2) {
                    transform: translateX(-10px);
                    opacity: 0; }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(7px, -6px); } } }
        span {
            background-color: $primary;
            position: absolute;
            width: 100%;
            height: 3px;
            left: 0;
            right: 0;
            transition: $tr;
            &:nth-child(1) {
                top: 0; }
            &:nth-child(2) {
                top: 0;
                bottom: 0;
                margin: auto; }
            &:nth-child(3) {
                bottom: 0; } } } }
