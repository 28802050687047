.footer {
    background-color: $bg;
    padding: 80px 0;
    color: $darkgray;
    line-height: 1.73;
    margin-top: 45px;
    @include r($xs) {
        margin-top: 0;
        padding: 40px 0; }
    .label {
        @include r($xs) {
            margin-bottom: 7px; } }
    &-mail {
        @include r($xs) {
            margin-bottom: 28px; }
        &__link {
            color: $dark;
            font-family: $i-semibold;
            font-size: 18px;
            margin-bottom: 4px;
            border-bottom: 1px solid transparent;
            &:hover {
                color: $primary;
                border-color: $primary; } }
        &__text {
            font-size: 14px; } }
    &-payment {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &__icon {
            margin-right: 18px; } }
    &-top {
        margin-bottom: 75px;
        @include r($xs) {
            margin-bottom: 40px; } }
    &-menu {
        margin-bottom: 25px;
        &__list {
            display: flex;
            align-items: center;
            flex-wrap: wrap; }
        &__item {
            margin-right: 15px; }
        &__link {
            color: $darkgray;
            font-size: 14px;
            border-bottom: 1px solid transparent;
            &:hover {
                color: $primary;
                border-color: $primary; } } }
    &-copyright {
        font-size: 14px;
        span {
            margin-left: 10px; } } }
