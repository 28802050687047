%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-about1 {
	@extend %svg-common;
	background-position: 80.99808061420346% 24.52431289640592%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-about1 {
	background-position: 80.99808061420346% 24.52431289640592%;
}
@mixin svg-image-about1-hover {
	.svg-image-about1 {
		@include svg-pos-image-about1-hover
	}
}

.svg-image-about1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-about1-dims {
	width: 58px;
	height: 58px;
}

.svg-image-about2 {
	@extend %svg-common;
	background-position: 81.15384615384616% 0;
	background-size: (579/59)*100% (531/58)*100%;
	width: 59px;

}
@mixin svg-pos-image-about2 {
	background-position: 81.15384615384616% 0;
}
@mixin svg-image-about2-hover {
	.svg-image-about2 {
		@include svg-pos-image-about2-hover
	}
}

.svg-image-about2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/59)*100%;
}

.svg-image-about2-dims {
	width: 59px;
	height: 58px;
}

.svg-image-about3 {
	@extend %svg-common;
	background-position: 81.15384615384616% 12.26215644820296%;
	background-size: (579/59)*100% (531/58)*100%;
	width: 59px;

}
@mixin svg-pos-image-about3 {
	background-position: 81.15384615384616% 12.26215644820296%;
}
@mixin svg-image-about3-hover {
	.svg-image-about3 {
		@include svg-pos-image-about3-hover
	}
}

.svg-image-about3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/59)*100%;
}

.svg-image-about3-dims {
	width: 59px;
	height: 58px;
}

.svg-image-add {
	@extend %svg-common;
	background-position: 94.5750452079566% 53.46534653465346%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-add {
	background-position: 94.5750452079566% 53.46534653465346%;
}
@mixin svg-image-add-hover {
	.svg-image-add {
		@include svg-pos-image-add-hover
	}
}

.svg-image-add:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-add-dims {
	width: 26px;
	height: 26px;
}

.svg-image-add-white {
	@extend %svg-common;
	background-position: 94.5750452079566% 58.613861386138616%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-add-white {
	background-position: 94.5750452079566% 58.613861386138616%;
}
@mixin svg-image-add-white-hover {
	.svg-image-add-white {
		@include svg-pos-image-add-white-hover
	}
}

.svg-image-add-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-add-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-analys-blue {
	@extend %svg-common;
	background-position: 16.237623762376238% 54.48577680525164%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-analys-blue {
	background-position: 16.237623762376238% 54.48577680525164%;
}
@mixin svg-image-analys-blue-hover {
	.svg-image-analys-blue {
		@include svg-pos-image-analys-blue-hover
	}
}

.svg-image-analys-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-analys-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-analys-white {
	@extend %svg-common;
	background-position: 30.89108910891089% 54.48577680525164%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-analys-white {
	background-position: 30.89108910891089% 54.48577680525164%;
}
@mixin svg-image-analys-white-hover {
	.svg-image-analys-white {
		@include svg-pos-image-analys-white-hover
	}
}

.svg-image-analys-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-analys-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-arrow-down-darkgray {
	@extend %svg-common;
	background-position: 94.5750452079566% 63.76237623762376%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-arrow-down-darkgray {
	background-position: 94.5750452079566% 63.76237623762376%;
}
@mixin svg-image-arrow-down-darkgray-hover {
	.svg-image-arrow-down-darkgray {
		@include svg-pos-image-arrow-down-darkgray-hover
	}
}

.svg-image-arrow-down-darkgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-arrow-down-darkgray-dims {
	width: 26px;
	height: 26px;
}

.svg-image-arrow-down-white {
	@extend %svg-common;
	background-position: 94.5750452079566% 68.91089108910892%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-arrow-down-white {
	background-position: 94.5750452079566% 68.91089108910892%;
}
@mixin svg-image-arrow-down-white-hover {
	.svg-image-arrow-down-white {
		@include svg-pos-image-arrow-down-white-hover
	}
}

.svg-image-arrow-down-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-arrow-down-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-arrow-left-blue {
	@extend %svg-common;
	background-position: 94.5750452079566% 74.05940594059406%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-arrow-left-blue {
	background-position: 94.5750452079566% 74.05940594059406%;
}
@mixin svg-image-arrow-left-blue-hover {
	.svg-image-arrow-left-blue {
		@include svg-pos-image-arrow-left-blue-hover
	}
}

.svg-image-arrow-left-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-arrow-left-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-arrow-right-white {
	@extend %svg-common;
	background-position: 94.5750452079566% 79.20792079207921%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-arrow-right-white {
	background-position: 94.5750452079566% 79.20792079207921%;
}
@mixin svg-image-arrow-right-white-hover {
	.svg-image-arrow-right-white {
		@include svg-pos-image-arrow-right-white-hover
	}
}

.svg-image-arrow-right-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-arrow-right-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-attach {
	@extend %svg-common;
	background-position: 94.5750452079566% 84.35643564356435%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-attach {
	background-position: 94.5750452079566% 84.35643564356435%;
}
@mixin svg-image-attach-hover {
	.svg-image-attach {
		@include svg-pos-image-attach-hover
	}
}

.svg-image-attach:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-attach-dims {
	width: 26px;
	height: 26px;
}

.svg-image-book-blue {
	@extend %svg-common;
	background-position: 94.5750452079566% 89.5049504950495%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-book-blue {
	background-position: 94.5750452079566% 89.5049504950495%;
}
@mixin svg-image-book-blue-hover {
	.svg-image-book-blue {
		@include svg-pos-image-book-blue-hover
	}
}

.svg-image-book-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-book-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-book-white {
	@extend %svg-common;
	background-position: 94.5750452079566% 94.65346534653466%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-book-white {
	background-position: 94.5750452079566% 94.65346534653466%;
}
@mixin svg-image-book-white-hover {
	.svg-image-book-white {
		@include svg-pos-image-book-white-hover
	}
}

.svg-image-book-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-book-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-check-blue {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-check-blue {
	background-position: 0 100%;
}
@mixin svg-image-check-blue-hover {
	.svg-image-check-blue {
		@include svg-pos-image-check-blue-hover
	}
}

.svg-image-check-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-check-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-clients1 {
	@extend %svg-common;
	background-position: 80.99808061420346% 36.78646934460888%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-clients1 {
	background-position: 80.99808061420346% 36.78646934460888%;
}
@mixin svg-image-clients1-hover {
	.svg-image-clients1 {
		@include svg-pos-image-clients1-hover
	}
}

.svg-image-clients1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-clients1-dims {
	width: 58px;
	height: 58px;
}

.svg-image-clients2 {
	@extend %svg-common;
	background-position: 80.99808061420346% 49.04862579281184%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-clients2 {
	background-position: 80.99808061420346% 49.04862579281184%;
}
@mixin svg-image-clients2-hover {
	.svg-image-clients2 {
		@include svg-pos-image-clients2-hover
	}
}

.svg-image-clients2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-clients2-dims {
	width: 58px;
	height: 58px;
}

.svg-image-clients3 {
	@extend %svg-common;
	background-position: 80.99808061420346% 61.3107822410148%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-clients3 {
	background-position: 80.99808061420346% 61.3107822410148%;
}
@mixin svg-image-clients3-hover {
	.svg-image-clients3 {
		@include svg-pos-image-clients3-hover
	}
}

.svg-image-clients3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-clients3-dims {
	width: 58px;
	height: 58px;
}

.svg-image-clients4 {
	@extend %svg-common;
	background-position: 0 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-clients4 {
	background-position: 0 85.62367864693447%;
}
@mixin svg-image-clients4-hover {
	.svg-image-clients4 {
		@include svg-pos-image-clients4-hover
	}
}

.svg-image-clients4:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-clients4-dims {
	width: 58px;
	height: 58px;
}

.svg-image-close-blue {
	@extend %svg-common;
	background-position: 98.57397504456328% 59.25925925925926%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-close-blue {
	background-position: 98.57397504456328% 59.25925925925926%;
}
@mixin svg-image-close-blue-hover {
	.svg-image-close-blue {
		@include svg-pos-image-close-blue-hover
	}
}

.svg-image-close-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-close-blue-dims {
	width: 18px;
	height: 18px;
}

.svg-image-close-dark {
	@extend %svg-common;
	background-position: 98.57397504456328% 62.76803118908382%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-close-dark {
	background-position: 98.57397504456328% 62.76803118908382%;
}
@mixin svg-image-close-dark-hover {
	.svg-image-close-dark {
		@include svg-pos-image-close-dark-hover
	}
}

.svg-image-close-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-close-dark-dims {
	width: 18px;
	height: 18px;
}

.svg-image-control-archive-dark {
	@extend %svg-common;
	background-position: 68.90130353817504% 67.68916155419222%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-archive-dark {
	background-position: 68.90130353817504% 67.68916155419222%;
}
@mixin svg-image-control-archive-dark-hover {
	.svg-image-control-archive-dark {
		@include svg-pos-image-control-archive-dark-hover
	}
}

.svg-image-control-archive-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-archive-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-archive-noborder {
	@extend %svg-common;
	background-position: 64.80446927374301% 82.82208588957056%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-archive-noborder {
	background-position: 64.80446927374301% 82.82208588957056%;
}
@mixin svg-image-control-archive-noborder-hover {
	.svg-image-control-archive-noborder {
		@include svg-pos-image-control-archive-noborder-hover
	}
}

.svg-image-control-archive-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-archive-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-archive-white {
	@extend %svg-common;
	background-position: 72.62569832402235% 82.82208588957056%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-archive-white {
	background-position: 72.62569832402235% 82.82208588957056%;
}
@mixin svg-image-control-archive-white-hover {
	.svg-image-control-archive-white {
		@include svg-pos-image-control-archive-white-hover
	}
}

.svg-image-control-archive-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-archive-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-control-blue {
	@extend %svg-common;
	background-position: 85.24590163934427% 92.41516966067864%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-control-blue {
	background-position: 85.24590163934427% 92.41516966067864%;
}
@mixin svg-image-control-control-blue-hover {
	.svg-image-control-control-blue {
		@include svg-pos-image-control-control-blue-hover
	}
}

.svg-image-control-control-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-control-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-control-lightgray {
	@extend %svg-common;
	background-position: 95.26411657559198% 0;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-control-lightgray {
	background-position: 95.26411657559198% 0;
}
@mixin svg-image-control-control-lightgray-hover {
	.svg-image-control-control-lightgray {
		@include svg-pos-image-control-control-lightgray-hover
	}
}

.svg-image-control-control-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-control-lightgray-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-delete-dark {
	@extend %svg-common;
	background-position: 80.44692737430168% 82.82208588957056%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-delete-dark {
	background-position: 80.44692737430168% 82.82208588957056%;
}
@mixin svg-image-control-delete-dark-hover {
	.svg-image-control-delete-dark {
		@include svg-pos-image-control-delete-dark-hover
	}
}

.svg-image-control-delete-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-delete-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-delete-noborder {
	@extend %svg-common;
	background-position: 89.57169459962756% 0;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-delete-noborder {
	background-position: 89.57169459962756% 0;
}
@mixin svg-image-control-delete-noborder-hover {
	.svg-image-control-delete-noborder {
		@include svg-pos-image-control-delete-noborder-hover
	}
}

.svg-image-control-delete-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-delete-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-delete-red {
	@extend %svg-common;
	background-position: 89.57169459962756% 8.588957055214724%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-delete-red {
	background-position: 89.57169459962756% 8.588957055214724%;
}
@mixin svg-image-control-delete-red-hover {
	.svg-image-control-delete-red {
		@include svg-pos-image-control-delete-red-hover
	}
}

.svg-image-control-delete-red:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-delete-red-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-pause-blue {
	@extend %svg-common;
	background-position: 95.26411657559198% 5.9880239520958085%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-pause-blue {
	background-position: 95.26411657559198% 5.9880239520958085%;
}
@mixin svg-image-control-pause-blue-hover {
	.svg-image-control-pause-blue {
		@include svg-pos-image-control-pause-blue-hover
	}
}

.svg-image-control-pause-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-pause-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-pause-dark {
	@extend %svg-common;
	background-position: 89.57169459962756% 17.177914110429448%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-pause-dark {
	background-position: 89.57169459962756% 17.177914110429448%;
}
@mixin svg-image-control-pause-dark-hover {
	.svg-image-control-pause-dark {
		@include svg-pos-image-control-pause-dark-hover
	}
}

.svg-image-control-pause-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-pause-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-pause-lightgray {
	@extend %svg-common;
	background-position: 95.26411657559198% 11.976047904191617%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-pause-lightgray {
	background-position: 95.26411657559198% 11.976047904191617%;
}
@mixin svg-image-control-pause-lightgray-hover {
	.svg-image-control-pause-lightgray {
		@include svg-pos-image-control-pause-lightgray-hover
	}
}

.svg-image-control-pause-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-pause-lightgray-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-pause-noborder {
	@extend %svg-common;
	background-position: 89.57169459962756% 25.766871165644172%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-pause-noborder {
	background-position: 89.57169459962756% 25.766871165644172%;
}
@mixin svg-image-control-pause-noborder-hover {
	.svg-image-control-pause-noborder {
		@include svg-pos-image-control-pause-noborder-hover
	}
}

.svg-image-control-pause-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-pause-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-pause-white {
	@extend %svg-common;
	background-position: 89.57169459962756% 34.355828220858896%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-pause-white {
	background-position: 89.57169459962756% 34.355828220858896%;
}
@mixin svg-image-control-pause-white-hover {
	.svg-image-control-pause-white {
		@include svg-pos-image-control-pause-white-hover
	}
}

.svg-image-control-pause-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-pause-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-pin-dark {
	@extend %svg-common;
	background-position: 4.701627486437613% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-control-pin-dark {
	background-position: 4.701627486437613% 100%;
}
@mixin svg-image-control-pin-dark-hover {
	.svg-image-control-pin-dark {
		@include svg-pos-image-control-pin-dark-hover
	}
}

.svg-image-control-pin-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-control-pin-dark-dims {
	width: 26px;
	height: 26px;
}

.svg-image-control-pin-gray {
	@extend %svg-common;
	background-position: 77.43119266055047% 74.44668008048289%;
	background-size: (579/34)*100% (531/34)*100%;
	width: 34px;

}
@mixin svg-pos-image-control-pin-gray {
	background-position: 77.43119266055047% 74.44668008048289%;
}
@mixin svg-image-control-pin-gray-hover {
	.svg-image-control-pin-gray {
		@include svg-pos-image-control-pin-gray-hover
	}
}

.svg-image-control-pin-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/34)*100%;
}

.svg-image-control-pin-gray-dims {
	width: 34px;
	height: 34px;
}

.svg-image-control-pin-lightgray {
	@extend %svg-common;
	background-position: 9.403254972875226% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-control-pin-lightgray {
	background-position: 9.403254972875226% 100%;
}
@mixin svg-image-control-pin-lightgray-hover {
	.svg-image-control-pin-lightgray {
		@include svg-pos-image-control-pin-lightgray-hover
	}
}

.svg-image-control-pin-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-control-pin-lightgray-dims {
	width: 26px;
	height: 26px;
}

.svg-image-control-pin-primary {
	@extend %svg-common;
	background-position: 55.77981651376147% 58.14889336016097%;
	background-size: (579/34)*100% (531/34)*100%;
	width: 34px;

}
@mixin svg-pos-image-control-pin-primary {
	background-position: 55.77981651376147% 58.14889336016097%;
}
@mixin svg-image-control-pin-primary-hover {
	.svg-image-control-pin-primary {
		@include svg-pos-image-control-pin-primary-hover
	}
}

.svg-image-control-pin-primary:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/34)*100%;
}

.svg-image-control-pin-primary-dims {
	width: 34px;
	height: 34px;
}

.svg-image-control-pin-white {
	@extend %svg-common;
	background-position: 14.104882459312838% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-control-pin-white {
	background-position: 14.104882459312838% 100%;
}
@mixin svg-image-control-pin-white-hover {
	.svg-image-control-pin-white {
		@include svg-pos-image-control-pin-white-hover
	}
}

.svg-image-control-pin-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-control-pin-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-control-repeat-blue {
	@extend %svg-common;
	background-position: 89.57169459962756% 42.94478527607362%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-repeat-blue {
	background-position: 89.57169459962756% 42.94478527607362%;
}
@mixin svg-image-control-repeat-blue-hover {
	.svg-image-control-repeat-blue {
		@include svg-pos-image-control-repeat-blue-hover
	}
}

.svg-image-control-repeat-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-repeat-blue-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-repeat-dark {
	@extend %svg-common;
	background-position: 89.57169459962756% 51.533742331288344%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-repeat-dark {
	background-position: 89.57169459962756% 51.533742331288344%;
}
@mixin svg-image-control-repeat-dark-hover {
	.svg-image-control-repeat-dark {
		@include svg-pos-image-control-repeat-dark-hover
	}
}

.svg-image-control-repeat-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-repeat-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-repeat-noborder {
	@extend %svg-common;
	background-position: 89.57169459962756% 60.122699386503065%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-repeat-noborder {
	background-position: 89.57169459962756% 60.122699386503065%;
}
@mixin svg-image-control-repeat-noborder-hover {
	.svg-image-control-repeat-noborder {
		@include svg-pos-image-control-repeat-noborder-hover
	}
}

.svg-image-control-repeat-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-repeat-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-repeat-white {
	@extend %svg-common;
	background-position: 89.57169459962756% 68.71165644171779%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-repeat-white {
	background-position: 89.57169459962756% 68.71165644171779%;
}
@mixin svg-image-control-repeat-white-hover {
	.svg-image-control-repeat-white {
		@include svg-pos-image-control-repeat-white-hover
	}
}

.svg-image-control-repeat-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-repeat-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-restore-blue {
	@extend %svg-common;
	background-position: 89.57169459962756% 77.30061349693251%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-restore-blue {
	background-position: 89.57169459962756% 77.30061349693251%;
}
@mixin svg-image-control-restore-blue-hover {
	.svg-image-control-restore-blue {
		@include svg-pos-image-control-restore-blue-hover
	}
}

.svg-image-control-restore-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-restore-blue-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-restore-noborder {
	@extend %svg-common;
	background-position: 89.57169459962756% 85.88957055214723%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-restore-noborder {
	background-position: 89.57169459962756% 85.88957055214723%;
}
@mixin svg-image-control-restore-noborder-hover {
	.svg-image-control-restore-noborder {
		@include svg-pos-image-control-restore-noborder-hover
	}
}

.svg-image-control-restore-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-restore-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-restore-white {
	@extend %svg-common;
	background-position: 0 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-restore-white {
	background-position: 0 94.68302658486708%;
}
@mixin svg-image-control-restore-white-hover {
	.svg-image-control-restore-white {
		@include svg-pos-image-control-restore-white-hover
	}
}

.svg-image-control-restore-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-restore-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-return-blue {
	@extend %svg-common;
	background-position: 95.26411657559198% 17.964071856287426%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-return-blue {
	background-position: 95.26411657559198% 17.964071856287426%;
}
@mixin svg-image-control-return-blue-hover {
	.svg-image-control-return-blue {
		@include svg-pos-image-control-return-blue-hover
	}
}

.svg-image-control-return-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-return-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-return-inter-blue {
	@extend %svg-common;
	background-position: 95.26411657559198% 23.952095808383234%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-return-inter-blue {
	background-position: 95.26411657559198% 23.952095808383234%;
}
@mixin svg-image-control-return-inter-blue-hover {
	.svg-image-control-return-inter-blue {
		@include svg-pos-image-control-return-inter-blue-hover
	}
}

.svg-image-control-return-inter-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-return-inter-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-return-inter-lightgray {
	@extend %svg-common;
	background-position: 95.26411657559198% 29.940119760479043%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-return-inter-lightgray {
	background-position: 95.26411657559198% 29.940119760479043%;
}
@mixin svg-image-control-return-inter-lightgray-hover {
	.svg-image-control-return-inter-lightgray {
		@include svg-pos-image-control-return-inter-lightgray-hover
	}
}

.svg-image-control-return-inter-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-return-inter-lightgray-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-return-lightgray {
	@extend %svg-common;
	background-position: 95.26411657559198% 35.92814371257485%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-return-lightgray {
	background-position: 95.26411657559198% 35.92814371257485%;
}
@mixin svg-image-control-return-lightgray-hover {
	.svg-image-control-return-lightgray {
		@include svg-pos-image-control-return-lightgray-hover
	}
}

.svg-image-control-return-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-return-lightgray-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-settings-dark {
	@extend %svg-common;
	background-position: 7.82122905027933% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-settings-dark {
	background-position: 7.82122905027933% 94.68302658486708%;
}
@mixin svg-image-control-settings-dark-hover {
	.svg-image-control-settings-dark {
		@include svg-pos-image-control-settings-dark-hover
	}
}

.svg-image-control-settings-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-settings-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-settings-noborder {
	@extend %svg-common;
	background-position: 15.64245810055866% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-settings-noborder {
	background-position: 15.64245810055866% 94.68302658486708%;
}
@mixin svg-image-control-settings-noborder-hover {
	.svg-image-control-settings-noborder {
		@include svg-pos-image-control-settings-noborder-hover
	}
}

.svg-image-control-settings-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-settings-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-settings-white {
	@extend %svg-common;
	background-position: 23.463687150837988% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-settings-white {
	background-position: 23.463687150837988% 94.68302658486708%;
}
@mixin svg-image-control-settings-white-hover {
	.svg-image-control-settings-white {
		@include svg-pos-image-control-settings-white-hover
	}
}

.svg-image-control-settings-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-settings-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-start-blue {
	@extend %svg-common;
	background-position: 95.26411657559198% 41.91616766467066%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-start-blue {
	background-position: 95.26411657559198% 41.91616766467066%;
}
@mixin svg-image-control-start-blue-hover {
	.svg-image-control-start-blue {
		@include svg-pos-image-control-start-blue-hover
	}
}

.svg-image-control-start-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-start-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-start-dark {
	@extend %svg-common;
	background-position: 31.28491620111732% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-start-dark {
	background-position: 31.28491620111732% 94.68302658486708%;
}
@mixin svg-image-control-start-dark-hover {
	.svg-image-control-start-dark {
		@include svg-pos-image-control-start-dark-hover
	}
}

.svg-image-control-start-dark:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-start-dark-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-start-lightgray {
	@extend %svg-common;
	background-position: 95.26411657559198% 47.90419161676647%;
	background-size: (579/30)*100% (531/30)*100%;
	width: 30px;

}
@mixin svg-pos-image-control-start-lightgray {
	background-position: 95.26411657559198% 47.90419161676647%;
}
@mixin svg-image-control-start-lightgray-hover {
	.svg-image-control-start-lightgray {
		@include svg-pos-image-control-start-lightgray-hover
	}
}

.svg-image-control-start-lightgray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (30/30)*100%;
}

.svg-image-control-start-lightgray-dims {
	width: 30px;
	height: 30px;
}

.svg-image-control-start-noborder {
	@extend %svg-common;
	background-position: 39.10614525139665% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-start-noborder {
	background-position: 39.10614525139665% 94.68302658486708%;
}
@mixin svg-image-control-start-noborder-hover {
	.svg-image-control-start-noborder {
		@include svg-pos-image-control-start-noborder-hover
	}
}

.svg-image-control-start-noborder:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-start-noborder-dims {
	width: 42px;
	height: 42px;
}

.svg-image-control-start-white {
	@extend %svg-common;
	background-position: 46.927374301675975% 94.68302658486708%;
	background-size: (579/42)*100% (531/42)*100%;
	width: 42px;

}
@mixin svg-pos-image-control-start-white {
	background-position: 46.927374301675975% 94.68302658486708%;
}
@mixin svg-image-control-start-white-hover {
	.svg-image-control-start-white {
		@include svg-pos-image-control-start-white-hover
	}
}

.svg-image-control-start-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (42/42)*100%;
}

.svg-image-control-start-white-dims {
	width: 42px;
	height: 42px;
}

.svg-image-coop1 {
	@extend %svg-common;
	background-position: 11.132437619961612% 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-coop1 {
	background-position: 11.132437619961612% 85.62367864693447%;
}
@mixin svg-image-coop1-hover {
	.svg-image-coop1 {
		@include svg-pos-image-coop1-hover
	}
}

.svg-image-coop1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-coop1-dims {
	width: 58px;
	height: 58px;
}

.svg-image-coop2 {
	@extend %svg-common;
	background-position: 22.264875239923224% 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-coop2 {
	background-position: 22.264875239923224% 85.62367864693447%;
}
@mixin svg-image-coop2-hover {
	.svg-image-coop2 {
		@include svg-pos-image-coop2-hover
	}
}

.svg-image-coop2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-coop2-dims {
	width: 58px;
	height: 58px;
}

.svg-image-coop3 {
	@extend %svg-common;
	background-position: 33.397312859884835% 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-coop3 {
	background-position: 33.397312859884835% 85.62367864693447%;
}
@mixin svg-image-coop3-hover {
	.svg-image-coop3 {
		@include svg-pos-image-coop3-hover
	}
}

.svg-image-coop3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-coop3-dims {
	width: 58px;
	height: 58px;
}

.svg-image-coop4 {
	@extend %svg-common;
	background-position: 44.52975047984645% 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-coop4 {
	background-position: 44.52975047984645% 85.62367864693447%;
}
@mixin svg-image-coop4-hover {
	.svg-image-coop4 {
		@include svg-pos-image-coop4-hover
	}
}

.svg-image-coop4:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-coop4-dims {
	width: 58px;
	height: 58px;
}

.svg-image-crypto {
	@extend %svg-common;
	background-position: 56.822429906542055% 50.712830957230146%;
	background-size: (579/44)*100% (531/40)*100%;
	width: 44px;

}
@mixin svg-pos-image-crypto {
	background-position: 56.822429906542055% 50.712830957230146%;
}
@mixin svg-image-crypto-hover {
	.svg-image-crypto {
		@include svg-pos-image-crypto-hover
	}
}

.svg-image-crypto:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (40/44)*100%;
}

.svg-image-crypto-dims {
	width: 44px;
	height: 40px;
}

.svg-image-doc-blue {
	@extend %svg-common;
	background-position: 18.806509945750452% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-doc-blue {
	background-position: 18.806509945750452% 100%;
}
@mixin svg-image-doc-blue-hover {
	.svg-image-doc-blue {
		@include svg-pos-image-doc-blue-hover
	}
}

.svg-image-doc-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-doc-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-filter-blue {
	@extend %svg-common;
	background-position: 23.508137432188065% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-filter-blue {
	background-position: 23.508137432188065% 100%;
}
@mixin svg-image-filter-blue-hover {
	.svg-image-filter-blue {
		@include svg-pos-image-filter-blue-hover
	}
}

.svg-image-filter-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-filter-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-filter-gray {
	@extend %svg-common;
	background-position: 28.209764918625677% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-filter-gray {
	background-position: 28.209764918625677% 100%;
}
@mixin svg-image-filter-gray-hover {
	.svg-image-filter-gray {
		@include svg-pos-image-filter-gray-hover
	}
}

.svg-image-filter-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-filter-gray-dims {
	width: 26px;
	height: 26px;
}

.svg-image-how1 {
	@extend %svg-common;
	background-position: 53.521126760563384% 0;
	background-size: (579/82)*100% (531/82)*100%;
	width: 82px;

}
@mixin svg-pos-image-how1 {
	background-position: 53.521126760563384% 0;
}
@mixin svg-image-how1-hover {
	.svg-image-how1 {
		@include svg-pos-image-how1-hover
	}
}

.svg-image-how1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (82/82)*100%;
}

.svg-image-how1-dims {
	width: 82px;
	height: 82px;
}

.svg-image-how2 {
	@extend %svg-common;
	background-position: 53.521126760563384% 18.262806236080177%;
	background-size: (579/82)*100% (531/82)*100%;
	width: 82px;

}
@mixin svg-pos-image-how2 {
	background-position: 53.521126760563384% 18.262806236080177%;
}
@mixin svg-image-how2-hover {
	.svg-image-how2 {
		@include svg-pos-image-how2-hover
	}
}

.svg-image-how2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (82/82)*100%;
}

.svg-image-how2-dims {
	width: 82px;
	height: 82px;
}

.svg-image-how3 {
	@extend %svg-common;
	background-position: 53.521126760563384% 36.525612472160354%;
	background-size: (579/82)*100% (531/82)*100%;
	width: 82px;

}
@mixin svg-pos-image-how3 {
	background-position: 53.521126760563384% 36.525612472160354%;
}
@mixin svg-image-how3-hover {
	.svg-image-how3 {
		@include svg-pos-image-how3-hover
	}
}

.svg-image-how3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (82/82)*100%;
}

.svg-image-how3-dims {
	width: 82px;
	height: 82px;
}

.svg-image-how4 {
	@extend %svg-common;
	background-position: 0 55.45657015590201%;
	background-size: (579/82)*100% (531/82)*100%;
	width: 82px;

}
@mixin svg-pos-image-how4 {
	background-position: 0 55.45657015590201%;
}
@mixin svg-image-how4-hover {
	.svg-image-how4 {
		@include svg-pos-image-how4-hover
	}
}

.svg-image-how4:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (82/82)*100%;
}

.svg-image-how4-dims {
	width: 82px;
	height: 82px;
}

.svg-image-indicator-circle {
	@extend %svg-common;
	background-position: 98.57397504456328% 66.27680311890838%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-indicator-circle {
	background-position: 98.57397504456328% 66.27680311890838%;
}
@mixin svg-image-indicator-circle-hover {
	.svg-image-indicator-circle {
		@include svg-pos-image-indicator-circle-hover
	}
}

.svg-image-indicator-circle:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-indicator-circle-dims {
	width: 18px;
	height: 18px;
}

.svg-image-info-blue {
	@extend %svg-common;
	background-position: 98.57397504456328% 69.78557504873294%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-info-blue {
	background-position: 98.57397504456328% 69.78557504873294%;
}
@mixin svg-image-info-blue-hover {
	.svg-image-info-blue {
		@include svg-pos-image-info-blue-hover
	}
}

.svg-image-info-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-info-blue-dims {
	width: 18px;
	height: 18px;
}

.svg-image-info-gray {
	@extend %svg-common;
	background-position: 98.57397504456328% 73.2943469785575%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-info-gray {
	background-position: 98.57397504456328% 73.2943469785575%;
}
@mixin svg-image-info-gray-hover {
	.svg-image-info-gray {
		@include svg-pos-image-info-gray-hover
	}
}

.svg-image-info-gray:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-info-gray-dims {
	width: 18px;
	height: 18px;
}

.svg-image-like-blue {
	@extend %svg-common;
	background-position: 45.54455445544554% 54.48577680525164%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-like-blue {
	background-position: 45.54455445544554% 54.48577680525164%;
}
@mixin svg-image-like-blue-hover {
	.svg-image-like-blue {
		@include svg-pos-image-like-blue-hover
	}
}

.svg-image-like-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-like-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-like-white {
	@extend %svg-common;
	background-position: 68.91089108910892% 0;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-like-white {
	background-position: 68.91089108910892% 0;
}
@mixin svg-image-like-white-hover {
	.svg-image-like-white {
		@include svg-pos-image-like-white-hover
	}
}

.svg-image-like-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-like-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-mastercard {
	@extend %svg-common;
	background-position: 67.88990825688073% 73.86138613861387%;
	background-size: (579/34)*100% (531/26)*100%;
	width: 34px;

}
@mixin svg-pos-image-mastercard {
	background-position: 67.88990825688073% 73.86138613861387%;
}
@mixin svg-image-mastercard-hover {
	.svg-image-mastercard {
		@include svg-pos-image-mastercard-hover
	}
}

.svg-image-mastercard:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/34)*100%;
}

.svg-image-mastercard-dims {
	width: 34px;
	height: 26px;
}

.svg-image-menu-api {
	@extend %svg-common;
	background-position: 32.91139240506329% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-api {
	background-position: 32.91139240506329% 100%;
}
@mixin svg-image-menu-api-hover {
	.svg-image-menu-api {
		@include svg-pos-image-menu-api-hover
	}
}

.svg-image-menu-api:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-api-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-api-blue {
	@extend %svg-common;
	background-position: 37.613019891500905% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-api-blue {
	background-position: 37.613019891500905% 100%;
}
@mixin svg-image-menu-api-blue-hover {
	.svg-image-menu-api-blue {
		@include svg-pos-image-menu-api-blue-hover
	}
}

.svg-image-menu-api-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-api-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-api-white {
	@extend %svg-common;
	background-position: 42.31464737793852% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-api-white {
	background-position: 42.31464737793852% 100%;
}
@mixin svg-image-menu-api-white-hover {
	.svg-image-menu-api-white {
		@include svg-pos-image-menu-api-white-hover
	}
}

.svg-image-menu-api-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-api-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-channel {
	@extend %svg-common;
	background-position: 47.01627486437613% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-channel {
	background-position: 47.01627486437613% 100%;
}
@mixin svg-image-menu-channel-hover {
	.svg-image-menu-channel {
		@include svg-pos-image-menu-channel-hover
	}
}

.svg-image-menu-channel:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-channel-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-channel-blue {
	@extend %svg-common;
	background-position: 51.717902350813745% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-channel-blue {
	background-position: 51.717902350813745% 100%;
}
@mixin svg-image-menu-channel-blue-hover {
	.svg-image-menu-channel-blue {
		@include svg-pos-image-menu-channel-blue-hover
	}
}

.svg-image-menu-channel-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-channel-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-channel-white {
	@extend %svg-common;
	background-position: 56.41952983725135% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-channel-white {
	background-position: 56.41952983725135% 100%;
}
@mixin svg-image-menu-channel-white-hover {
	.svg-image-menu-channel-white {
		@include svg-pos-image-menu-channel-white-hover
	}
}

.svg-image-menu-channel-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-channel-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-reading {
	@extend %svg-common;
	background-position: 61.12115732368897% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-reading {
	background-position: 61.12115732368897% 100%;
}
@mixin svg-image-menu-reading-hover {
	.svg-image-menu-reading {
		@include svg-pos-image-menu-reading-hover
	}
}

.svg-image-menu-reading:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-reading-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-reading-blue {
	@extend %svg-common;
	background-position: 65.82278481012658% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-reading-blue {
	background-position: 65.82278481012658% 100%;
}
@mixin svg-image-menu-reading-blue-hover {
	.svg-image-menu-reading-blue {
		@include svg-pos-image-menu-reading-blue-hover
	}
}

.svg-image-menu-reading-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-reading-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-reading-white {
	@extend %svg-common;
	background-position: 70.5244122965642% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-reading-white {
	background-position: 70.5244122965642% 100%;
}
@mixin svg-image-menu-reading-white-hover {
	.svg-image-menu-reading-white {
		@include svg-pos-image-menu-reading-white-hover
	}
}

.svg-image-menu-reading-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-reading-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-support {
	@extend %svg-common;
	background-position: 75.22603978300181% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-support {
	background-position: 75.22603978300181% 100%;
}
@mixin svg-image-menu-support-hover {
	.svg-image-menu-support {
		@include svg-pos-image-menu-support-hover
	}
}

.svg-image-menu-support:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-support-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-support-blue {
	@extend %svg-common;
	background-position: 79.92766726943943% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-support-blue {
	background-position: 79.92766726943943% 100%;
}
@mixin svg-image-menu-support-blue-hover {
	.svg-image-menu-support-blue {
		@include svg-pos-image-menu-support-blue-hover
	}
}

.svg-image-menu-support-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-support-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-support-white {
	@extend %svg-common;
	background-position: 84.62929475587704% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-support-white {
	background-position: 84.62929475587704% 100%;
}
@mixin svg-image-menu-support-white-hover {
	.svg-image-menu-support-white {
		@include svg-pos-image-menu-support-white-hover
	}
}

.svg-image-menu-support-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-support-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-tasks {
	@extend %svg-common;
	background-position: 89.33092224231464% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-tasks {
	background-position: 89.33092224231464% 100%;
}
@mixin svg-image-menu-tasks-hover {
	.svg-image-menu-tasks {
		@include svg-pos-image-menu-tasks-hover
	}
}

.svg-image-menu-tasks:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-tasks-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-tasks-blue {
	@extend %svg-common;
	background-position: 94.03254972875226% 100%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-tasks-blue {
	background-position: 94.03254972875226% 100%;
}
@mixin svg-image-menu-tasks-blue-hover {
	.svg-image-menu-tasks-blue {
		@include svg-pos-image-menu-tasks-blue-hover
	}
}

.svg-image-menu-tasks-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-tasks-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-menu-tasks-white {
	@extend %svg-common;
	background-position: 100% 0;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-menu-tasks-white {
	background-position: 100% 0;
}
@mixin svg-image-menu-tasks-white-hover {
	.svg-image-menu-tasks-white {
		@include svg-pos-image-menu-tasks-white-hover
	}
}

.svg-image-menu-tasks-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-menu-tasks-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-password-hide {
	@extend %svg-common;
	background-position: 98.57397504456328% 76.80311890838206%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-password-hide {
	background-position: 98.57397504456328% 76.80311890838206%;
}
@mixin svg-image-password-hide-hover {
	.svg-image-password-hide {
		@include svg-pos-image-password-hide-hover
	}
}

.svg-image-password-hide:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-password-hide-dims {
	width: 18px;
	height: 18px;
}

.svg-image-password-show {
	@extend %svg-common;
	background-position: 98.57397504456328% 80.31189083820662%;
	background-size: (579/18)*100% (531/18)*100%;
	width: 18px;

}
@mixin svg-pos-image-password-show {
	background-position: 98.57397504456328% 80.31189083820662%;
}
@mixin svg-image-password-show-hover {
	.svg-image-password-show {
		@include svg-pos-image-password-show-hover
	}
}

.svg-image-password-show:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-password-show-dims {
	width: 18px;
	height: 18px;
}

.svg-image-payment-apple-pay {
	@extend %svg-common;
	background-position: 67.83625730994152% 58.153241650294696%;
	background-size: (579/66)*100% (531/22)*100%;
	width: 66px;

}
@mixin svg-pos-image-payment-apple-pay {
	background-position: 67.83625730994152% 58.153241650294696%;
}
@mixin svg-image-payment-apple-pay-hover {
	.svg-image-payment-apple-pay {
		@include svg-pos-image-payment-apple-pay-hover
	}
}

.svg-image-payment-apple-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/66)*100%;
}

.svg-image-payment-apple-pay-dims {
	width: 66px;
	height: 22px;
}

.svg-image-payment-card {
	@extend %svg-common;
	background-position: 55.66218809980806% 85.62367864693447%;
	background-size: (579/58)*100% (531/58)*100%;
	width: 58px;

}
@mixin svg-pos-image-payment-card {
	background-position: 55.66218809980806% 85.62367864693447%;
}
@mixin svg-image-payment-card-hover {
	.svg-image-payment-card {
		@include svg-pos-image-payment-card-hover
	}
}

.svg-image-payment-card:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (58/58)*100%;
}

.svg-image-payment-card-dims {
	width: 58px;
	height: 58px;
}

.svg-image-payment-google-pay {
	@extend %svg-common;
	background-position: 80.07590132827325% 68.36935166994107%;
	background-size: (579/52)*100% (531/22)*100%;
	width: 52px;

}
@mixin svg-pos-image-payment-google-pay {
	background-position: 80.07590132827325% 68.36935166994107%;
}
@mixin svg-image-payment-google-pay-hover {
	.svg-image-payment-google-pay {
		@include svg-pos-image-payment-google-pay-hover
	}
}

.svg-image-payment-google-pay:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/52)*100%;
}

.svg-image-payment-google-pay-dims {
	width: 52px;
	height: 22px;
}

.svg-image-paypal {
	@extend %svg-common;
	background-position: 98.75% 55.40275049115913%;
	background-size: (579/19)*100% (531/22)*100%;
	width: 19px;

}
@mixin svg-pos-image-paypal {
	background-position: 98.75% 55.40275049115913%;
}
@mixin svg-image-paypal-hover {
	.svg-image-paypal {
		@include svg-pos-image-paypal-hover
	}
}

.svg-image-paypal:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/19)*100%;
}

.svg-image-paypal-dims {
	width: 19px;
	height: 22px;
}

.svg-image-prevention-blue {
	@extend %svg-common;
	background-position: 68.91089108910892% 16.192560175054705%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-prevention-blue {
	background-position: 68.91089108910892% 16.192560175054705%;
}
@mixin svg-image-prevention-blue-hover {
	.svg-image-prevention-blue {
		@include svg-pos-image-prevention-blue-hover
	}
}

.svg-image-prevention-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-prevention-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-prevention-white {
	@extend %svg-common;
	background-position: 68.91089108910892% 32.38512035010941%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-prevention-white {
	background-position: 68.91089108910892% 32.38512035010941%;
}
@mixin svg-image-prevention-white-hover {
	.svg-image-prevention-white {
		@include svg-pos-image-prevention-white-hover
	}
}

.svg-image-prevention-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-prevention-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-promo-img {
	@extend %svg-common;
	background-position: 0 0;
	background-size: (579/133)*100% (531/128)*100%;
	width: 133px;

}
@mixin svg-pos-image-promo-img {
	background-position: 0 0;
}
@mixin svg-image-promo-img-hover {
	.svg-image-promo-img {
		@include svg-pos-image-promo-img-hover
	}
}

.svg-image-promo-img:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (128/133)*100%;
}

.svg-image-promo-img-dims {
	width: 133px;
	height: 128px;
}

.svg-image-qiwi {
	@extend %svg-common;
	background-position: 99.63963963963964% 50.887573964497044%;
	background-size: (579/24)*100% (531/24)*100%;
	width: 24px;

}
@mixin svg-pos-image-qiwi {
	background-position: 99.63963963963964% 50.887573964497044%;
}
@mixin svg-image-qiwi-hover {
	.svg-image-qiwi {
		@include svg-pos-image-qiwi-hover
	}
}

.svg-image-qiwi:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (24/24)*100%;
}

.svg-image-qiwi-dims {
	width: 24px;
	height: 24px;
}

.svg-image-repeat-blue {
	@extend %svg-common;
	background-position: 100% 5.148514851485149%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-repeat-blue {
	background-position: 100% 5.148514851485149%;
}
@mixin svg-image-repeat-blue-hover {
	.svg-image-repeat-blue {
		@include svg-pos-image-repeat-blue-hover
	}
}

.svg-image-repeat-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-repeat-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-repeat-white {
	@extend %svg-common;
	background-position: 100% 10.297029702970297%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-repeat-white {
	background-position: 100% 10.297029702970297%;
}
@mixin svg-image-repeat-white-hover {
	.svg-image-repeat-white {
		@include svg-pos-image-repeat-white-hover
	}
}

.svg-image-repeat-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-repeat-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-signout {
	@extend %svg-common;
	background-position: 100% 15.445544554455445%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-signout {
	background-position: 100% 15.445544554455445%;
}
@mixin svg-image-signout-hover {
	.svg-image-signout {
		@include svg-pos-image-signout-hover
	}
}

.svg-image-signout:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-signout-dims {
	width: 26px;
	height: 26px;
}

.svg-image-status-archive {
	@extend %svg-common;
	background-position: 67.1559633027523% 93.158953722334%;
	background-size: (579/34)*100% (531/34)*100%;
	width: 34px;

}
@mixin svg-pos-image-status-archive {
	background-position: 67.1559633027523% 93.158953722334%;
}
@mixin svg-image-status-archive-hover {
	.svg-image-status-archive {
		@include svg-pos-image-status-archive-hover
	}
}

.svg-image-status-archive:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/34)*100%;
}

.svg-image-status-archive-dims {
	width: 34px;
	height: 34px;
}

.svg-image-status-done {
	@extend %svg-common;
	background-position: 73.39449541284404% 93.158953722334%;
	background-size: (579/34)*100% (531/34)*100%;
	width: 34px;

}
@mixin svg-pos-image-status-done {
	background-position: 73.39449541284404% 93.158953722334%;
}
@mixin svg-image-status-done-hover {
	.svg-image-status-done {
		@include svg-pos-image-status-done-hover
	}
}

.svg-image-status-done:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/34)*100%;
}

.svg-image-status-done-dims {
	width: 34px;
	height: 34px;
}

.svg-image-status-error {
	@extend %svg-common;
	background-position: 79.63302752293578% 93.158953722334%;
	background-size: (579/34)*100% (531/34)*100%;
	width: 34px;

}
@mixin svg-pos-image-status-error {
	background-position: 79.63302752293578% 93.158953722334%;
}
@mixin svg-image-status-error-hover {
	.svg-image-status-error {
		@include svg-pos-image-status-error-hover
	}
}

.svg-image-status-error:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (34/34)*100%;
}

.svg-image-status-error-dims {
	width: 34px;
	height: 34px;
}

.svg-image-status-unverified {
	@extend %svg-common;
	background-position: 54.14364640883978% 93.53535353535354%;
	background-size: (579/36)*100% (531/36)*100%;
	width: 36px;

}
@mixin svg-pos-image-status-unverified {
	background-position: 54.14364640883978% 93.53535353535354%;
}
@mixin svg-image-status-unverified-hover {
	.svg-image-status-unverified {
		@include svg-pos-image-status-unverified-hover
	}
}

.svg-image-status-unverified:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (36/36)*100%;
}

.svg-image-status-unverified-dims {
	width: 36px;
	height: 36px;
}

.svg-image-status-verified {
	@extend %svg-common;
	background-position: 60.773480662983424% 93.53535353535354%;
	background-size: (579/36)*100% (531/36)*100%;
	width: 36px;

}
@mixin svg-pos-image-status-verified {
	background-position: 60.773480662983424% 93.53535353535354%;
}
@mixin svg-image-status-verified-hover {
	.svg-image-status-verified {
		@include svg-pos-image-status-verified-hover
	}
}

.svg-image-status-verified:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (36/36)*100%;
}

.svg-image-status-verified-dims {
	width: 36px;
	height: 36px;
}

.svg-image-subs-blue {
	@extend %svg-common;
	background-position: 68.91089108910892% 48.57768052516411%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-subs-blue {
	background-position: 68.91089108910892% 48.57768052516411%;
}
@mixin svg-image-subs-blue-hover {
	.svg-image-subs-blue {
		@include svg-pos-image-subs-blue-hover
	}
}

.svg-image-subs-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-subs-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-subs-white {
	@extend %svg-common;
	background-position: 0 72.4288840262582%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-subs-white {
	background-position: 0 72.4288840262582%;
}
@mixin svg-image-subs-white-hover {
	.svg-image-subs-white {
		@include svg-pos-image-subs-white-hover
	}
}

.svg-image-subs-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-subs-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-tabs-blue {
	@extend %svg-common;
	background-position: 100% 20.594059405940595%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-tabs-blue {
	background-position: 100% 20.594059405940595%;
}
@mixin svg-image-tabs-blue-hover {
	.svg-image-tabs-blue {
		@include svg-pos-image-tabs-blue-hover
	}
}

.svg-image-tabs-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-tabs-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-tabs-white {
	@extend %svg-common;
	background-position: 100% 25.742574257425744%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-tabs-white {
	background-position: 100% 25.742574257425744%;
}
@mixin svg-image-tabs-white-hover {
	.svg-image-tabs-white {
		@include svg-pos-image-tabs-white-hover
	}
}

.svg-image-tabs-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-tabs-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-tariff1 {
	@extend %svg-common;
	background-position: 0 31.21951219512195%;
	background-size: (579/132)*100% (531/121)*100%;
	width: 132px;

}
@mixin svg-pos-image-tariff1 {
	background-position: 0 31.21951219512195%;
}
@mixin svg-image-tariff1-hover {
	.svg-image-tariff1 {
		@include svg-pos-image-tariff1-hover
	}
}

.svg-image-tariff1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (121/132)*100%;
}

.svg-image-tariff1-dims {
	width: 132px;
	height: 121px;
}

.svg-image-tariff2 {
	@extend %svg-common;
	background-position: 29.530201342281877% 31.21951219512195%;
	background-size: (579/132)*100% (531/121)*100%;
	width: 132px;

}
@mixin svg-pos-image-tariff2 {
	background-position: 29.530201342281877% 31.21951219512195%;
}
@mixin svg-image-tariff2-hover {
	.svg-image-tariff2 {
		@include svg-pos-image-tariff2-hover
	}
}

.svg-image-tariff2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (121/132)*100%;
}

.svg-image-tariff2-dims {
	width: 132px;
	height: 121px;
}

.svg-image-tariff3 {
	@extend %svg-common;
	background-position: 29.820627802690584% 0;
	background-size: (579/133)*100% (531/120)*100%;
	width: 133px;

}
@mixin svg-pos-image-tariff3 {
	background-position: 29.820627802690584% 0;
}
@mixin svg-image-tariff3-hover {
	.svg-image-tariff3 {
		@include svg-pos-image-tariff3-hover
	}
}

.svg-image-tariff3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (120/133)*100%;
}

.svg-image-tariff3-dims {
	width: 133px;
	height: 120px;
}

.svg-image-telegram-blue {
	@extend %svg-common;
	background-position: 100% 30.89108910891089%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-telegram-blue {
	background-position: 100% 30.89108910891089%;
}
@mixin svg-image-telegram-blue-hover {
	.svg-image-telegram-blue {
		@include svg-pos-image-telegram-blue-hover
	}
}

.svg-image-telegram-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-telegram-blue-dims {
	width: 26px;
	height: 26px;
}

.svg-image-telegram-white {
	@extend %svg-common;
	background-position: 100% 36.039603960396036%;
	background-size: (579/26)*100% (531/26)*100%;
	width: 26px;

}
@mixin svg-pos-image-telegram-white {
	background-position: 100% 36.039603960396036%;
}
@mixin svg-image-telegram-white-hover {
	.svg-image-telegram-white {
		@include svg-pos-image-telegram-white-hover
	}
}

.svg-image-telegram-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-telegram-white-dims {
	width: 26px;
	height: 26px;
}

.svg-image-unsub-blue {
	@extend %svg-common;
	background-position: 14.653465346534654% 72.4288840262582%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-unsub-blue {
	background-position: 14.653465346534654% 72.4288840262582%;
}
@mixin svg-image-unsub-blue-hover {
	.svg-image-unsub-blue {
		@include svg-pos-image-unsub-blue-hover
	}
}

.svg-image-unsub-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-unsub-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-unsub-white {
	@extend %svg-common;
	background-position: 29.306930693069308% 72.4288840262582%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-unsub-white {
	background-position: 29.306930693069308% 72.4288840262582%;
}
@mixin svg-image-unsub-white-hover {
	.svg-image-unsub-white {
		@include svg-pos-image-unsub-white-hover
	}
}

.svg-image-unsub-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-unsub-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-views-blue {
	@extend %svg-common;
	background-position: 43.960396039603964% 72.4288840262582%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-views-blue {
	background-position: 43.960396039603964% 72.4288840262582%;
}
@mixin svg-image-views-blue-hover {
	.svg-image-views-blue {
		@include svg-pos-image-views-blue-hover
	}
}

.svg-image-views-blue:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-views-blue-dims {
	width: 74px;
	height: 74px;
}

.svg-image-views-white {
	@extend %svg-common;
	background-position: 58.613861386138616% 72.4288840262582%;
	background-size: (579/74)*100% (531/74)*100%;
	width: 74px;

}
@mixin svg-pos-image-views-white {
	background-position: 58.613861386138616% 72.4288840262582%;
}
@mixin svg-image-views-white-hover {
	.svg-image-views-white {
		@include svg-pos-image-views-white-hover
	}
}

.svg-image-views-white:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (74/74)*100%;
}

.svg-image-views-white-dims {
	width: 74px;
	height: 74px;
}

.svg-image-webmoney {
	@extend %svg-common;
	background-position: 99.81949458483754% 46.15384615384615%;
	background-size: (579/25)*100% (531/24)*100%;
	width: 25px;

}
@mixin svg-pos-image-webmoney {
	background-position: 99.81949458483754% 46.15384615384615%;
}
@mixin svg-image-webmoney-hover {
	.svg-image-webmoney {
		@include svg-pos-image-webmoney-hover
	}
}

.svg-image-webmoney:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (24/25)*100%;
}

.svg-image-webmoney-dims {
	width: 25px;
	height: 24px;
}

.svg-image-yandex {
	@extend %svg-common;
	background-position: 99.10394265232975% 41.18811881188119%;
	background-size: (579/21)*100% (531/26)*100%;
	width: 21px;

}
@mixin svg-pos-image-yandex {
	background-position: 99.10394265232975% 41.18811881188119%;
}
@mixin svg-image-yandex-hover {
	.svg-image-yandex {
		@include svg-pos-image-yandex-hover
	}
}

.svg-image-yandex:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/21)*100%;
}

.svg-image-yandex-dims {
	width: 21px;
	height: 26px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }