.signup {
    position: relative;
    background: linear-gradient(256.57deg, #1160B7 5.62%, rgba(17, 96, 183, 0.72) 123.14%);
    min-height: calc(100vh - 100px);
    overflow: hidden;
    padding: 80px 0;
    &::before {
        content: '';
        display: block;
        width: 769px;
        height: 666px;
        background: url('../images/signup-bg.svg') 50% 50% no-repeat;
        position: absolute;
        top: 260px;
        left: 50%;
        transform: translateX(-95%); }
    @media (max-width: 1199px) {
        &::before {
            transform: translateX(-90%); } }
    @media (max-width: 1023px) {
        &::before {
            top: 50px;
            transform: translateX(-60%); } }
    @media (max-width: 767px) {
        min-height: calc(100vh - 68px);
        padding: 55px 0;
        &::before {
            width: 353px;
            height: 562px;
            background: url('../images/signup-mob-bg.svg') 50% 50% no-repeat;
            top: 25px;
            transform: translateX(-53%); } }
    .container {
        position: relative;
        z-index: 2; }

    &__text {
        padding-top: 180px;
        @media (max-width: 1023px) {
            padding-top: 0; }
        @media (max-width: 767px) {
            margin-bottom: 16px; }
        img {
            margin-bottom: 30px;
            @media (max-width: 767px) {
                display: none; } }
        h1 {
            font-family: $i-semibold;
            line-height: 1;
            color: #fff;
            letter-spacing: -0.02em;
            margin-bottom: 20px;
            @include fluidFontSize(24px, 56px, 375px, 1280px);
            @media (max-width: 767px) {
                margin-bottom: 16px; } }
        p {
            max-width: 490px;
            font-size: 19px;
            line-height: 1.5;
            color: rgba(#fff, .7);
            letter-spacing: -0.4px;
            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 1.75; } } } }



.sign {
    background-color: #fff;
    border-radius: 32px;
    &__tabs {
        display: flex;
        border-bottom: 1px solid #BED9E5; }
    &__tab {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 80px;
        border-bottom: 2px solid transparent;
        font-family: $i-semibold;
        font-size: 16px;
        line-height: 1;
        transition: border .2s ease;
        user-select: none;
        cursor: pointer;
        margin-bottom: -1px;
        &.active {
            border-bottom: 2px solid #1160B7; }
        @media (max-width: 499px) {
            height: 70px; } }

    &-up {
        display: block;
        width: 100%;
        padding: 48px 52px 40px;
        @media (max-width: 1099px) and (min-width: 1024px) {
            padding-left: 40px;
            padding-right: 40px; }
        @media (max-width: 499px) {
            padding: 32px 32px 40px; }
        &__input {
            margin-bottom: 16px;
            label {
                display: block;
                font-family: $i-medium;
                font-size: 14px;
                line-height: 1.4;
                margin-bottom: 8px; }
            input {
                font-size: 14px;
                line-height: normal;
                border: 1px solid $lightgray;
                height: 46px;
                padding: 0 12px;
                margin: 0;
                color: $gray; } }
        &__agree {
            margin-bottom: 16px;
            .checkbox__label {
                font-size: 14px;
                color: $gray; } }
        &__captcha {
            margin-bottom: 32px; }
        .btn {
            width: 100%; } }
    &-in {
        &__forgot {
            margin: -8px 0 34px;
            a {
                font-family: $i-semibold;
                color: $secondary;
                &:hover {
                    color: $primary; } } } }
    .social-login {
        border-top: 1px solid #BED9E5;
        padding: 24px 52px 40px;
        @media (max-width: 1099px) and (min-width: 1024px) {
            padding-left: 40px;
            padding-right: 40px; }
        @media (max-width: 499px) {
            padding: 24px 32px 40px; }
        h6 {
            font-size: 16px;
            margin-bottom: 12px; }
        &__items {
            display: flex; }
        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            border: 1px solid #E4E9ED;
            border-radius: 4px;
            margin-right: 16px;
            &:last-child {
                margin: 0; }
            &:hover {
                border-color: $secondary; } } }

    &--inner {
        box-shadow: 0px 4px 19px rgba(0,0,0, .1);
        border-radius: 6px;
        .sign-up {
            padding: 32px 24px; }
        .social-login {
            padding: 24px 24px 32px; } } }
