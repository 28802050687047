.pc {
    background-color: $bg;
    font-size: 14px;
    line-height: 1.4;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    * {
        letter-spacing: -0.01em; }
    h1 {
        font-family: $i-semibold;
        line-height: 1.15;
        letter-spacing: -0.01em;
        @include fluidFontSize(22px, 40px, $xs, $xl);
        span {
            color: $secondary; } }
    h2 {
        @include fluidFontSize(22px, 32px, $xs, $xl); }
    h3 {
        @include fluidFontSize(18px, 22px, $xs, $xl); }
    h4 {
        @include fluidFontSize(18px, 18px, $xs, $xl); }
    h5 {
        @include fluidFontSize(16px, 16px, $xs, $xl); }
    h6 {
        @include fluidFontSize(14px, 14px, $xs, $xl); }
    .btn {
        font-family: $i-semibold; }
    .field {
        border: 1px solid $lightgray;
        font-size: 14px;
        padding: 0 14px;
        color: $gray;
        transition: $tr;
        &::placeholder {
            color: rgba(114, 123, 144, .8); }
        &:focus {
            box-shadow: 0px 1px 4px rgba(63, 68, 128, 0.05);
            border-color: #727b90; }
        &.focus {
            border-color: $gray;
            box-shadow: 0px 1px 4px rgba(63, 68, 128, 0.05); }
        &.error {
            border-color: $error!important;
            color: $error!important; }
        &:last-child {
            margin-bottom: 0; } }
    .has-error {
        .field, .select2-selection {
            border-color: $error!important;
            color: $error!important; } }
    .textarea {
        padding: 16px 14px;
        margin-bottom: 0; }
    &--fixed-btn {
        @include r($xs) {
            padding-bottom: 48px;
            .pc-sidebar {
                &__signout {
                    bottom: 70px; }
                &__content-inner {
                    padding-bottom: 110px; } } } }
    &-back {
        margin-bottom: 12px;
        &-link {
            display: inline-flex;
            align-items: center;
            padding: 10px 0;
            &__arrow {
                margin-right: 8px; }
            &__text {
                font-size: 16px;
                font-family: $i-medium;
                color: $secondary; } } }
    &-code {
        span {
            display: block; } }
    &-main {
        flex-grow: 1;
        margin-left: 272px;
        padding: 40px 0 70px;
        @include r($md) {
            margin-left: 250px;
            padding: 30px 0; }
        @include r($xs) {
            margin-left: 0;
            margin-top: 68px;
            padding: 16px 0 0; } }
    &-container {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 0 16px; }
    &-footer {
        margin-left: 272px;
        @include r($md) {
            margin-left: 250px; }
        @include r($xs) {
            margin-left: 0;
            margin-top: 24px; }
        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
            border-top: 1px solid rgba(228, 233, 237, .8);
            @include r($lg) {
                flex-wrap: wrap; } }
        &__item {
            color: $gray;
            @include r($lg) {
                width: 50%;
                padding: 8px 0;
                &:nth-child(1) {
                    order: 3; }
                &:nth-child(2) {
                    order: 2; }
                &:nth-child(3) {
                    order: 1; }
                &:nth-child(4) {
                    order: 4; } }
            @include r($xs) {
                width: 100%;
                text-align: center; } } }
    &-sidebar {
        width: 272px;
        position: fixed;
        z-index: 29;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #fff;
        padding: 38px 0;
        @include r($md) {
            width: 250px; }
        @include r($xs) {
            width: 100%;
            bottom: 0;
            height: 68px;
            right: 0;
            padding: 0;
            background-color: transparent;
            @include r($xs) {
                z-index: 31; } }
        &.active {
            height: 100%;
            z-index: 20;
            @include r($xs) {
                z-index: 31; } }
        &.animate {
            .pc-sidebar {
                &__burger {
                    &-notify {
                        opacity: 0; }
                    span {
                        background-color: $darkgray;
                        &:nth-child(1) {
                            transform: rotate(45deg) translate(7px,6px); }
                        &:nth-child(2) {
                            transform: translateX(-10px);
                            opacity: 0; }
                        &:nth-child(3) {
                            transform: rotate(-45deg) translate(7px,-6px); } } }
                &__content {
                    opacity: 1;
                    visibility: visible; } } }
        &__fixed-btn {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: none;
            @include r($xs) {
                display: block; }
            .btn {
                width: 100%;
                border-radius: 0; } }
        &__popup {
            display: none;
            &-link {
                display: inline-flex;
                align-items: center;
                &-icon {
                    margin-right: 4px;
                    width: 32px;
                    height: 32px; }
                &-text {
                    color: $darkgray;
                    margin: 0; } } }
        &__content {
            @include r($xs) {
                position: absolute;
                background-color: #fff;
                top: 68px;
                left: 0;
                right: 0;
                bottom: 0;
                height: calc(100% - 68px);
                opacity: 0;
                visibility: hidden;
                z-index: -1;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                transition: $tr; }
            &-inner {
                @include r($xs) {
                    padding: 16px 0 90px;
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                    position: relative;
                    min-height: 100%; } } }
        &__head {
            @include r($xs) {
                height: 68px;
                padding: 0 16px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;
                box-shadow: 0px 0px 2px rgba(63, 68, 128, 0.25);
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                &.popuped {
                    .pc-sidebar {
                        &__logo, &__balance {
                            display: none; }
                        &__popup {
                            display: flex; } } } } }
        &__balance {
            display: none;
            @include r($xs) {
                display: block;
                font-size: 16px;
                color: $gray;
                font-family: $i-semibold;
                margin: 0 20px 0 auto; } }
        &__burger {
            display: none;
            width: 32px;
            height: 32px;
            position: relative;
            cursor: pointer;
            @include r($xs) {
                display: flex;
                align-items: center;
                justify-content: center; }
            &-inner {
                width: 26px;
                height: 20px;
                position: relative; }
            &-notify {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $secondary;
                color: #fff;
                position: absolute;
                right: -6px;
                top: -6px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: $tr; }
            span {
                height: 2px;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                border-radius: 4px;
                background-color: $secondary;
                transition: $tr;
                &:nth-child(1) {
                    top: 0; }
                &:nth-child(2) {
                    bottom: 0;
                    top: 0;
                    margin: auto; }
                &:nth-child(3) {
                    bottom: 0; } } }
        &__logo {
            margin-bottom: 88px;
            text-align: center;
            @include r($xs) {
                margin-bottom: 0; } }
        &__item {
            margin-bottom: 8px;
            @include r($xs) {
                margin-bottom: 0; }
            &:last-child {
                margin-bottom: 0; } }
        &__menu {
            padding: 0 24px 12px 0;
            @include r($md) {
                padding: 0 16px 16px 0; }
            @include r($xs) {
                margin-top: 20px; } }
        &__link {
            padding: 0 40px 0 32px;
            height: 48px;
            line-height: 1.1;
            color: $gray;
            position: relative;
            display: flex;
            align-items: center;
            border-radius: 0 24px 24px 0;
            @include r($md) {
                padding: 0 20px 0 16px; }
            &:hover:not(.active) {
                color: $secondary;
                .pc-sidebar__link-icon {
                    &--hover {
                        opacity: 1; }
                    &--default {
                        opacity: 0; } } }
            &.active {
                background-color: $secondary;
                color: #fff;
                @include r($xs) {
                    background-color: transparent;
                    color: $secondary; }
                .pc-sidebar__link {
                    &-icon {
                        &--active {
                            opacity: 1;
                            @include r($xs) {
                                opacity: 0; } }
                        &--default {
                            opacity: 0; }
                        &--hover {
                            opacity: 1; } }
                    &-notify {
                        background-color: #fff;
                        color: $secondary;
                        @include r($xs) {
                            background-color: $secondary;
                            color: #fff; } } } }
            &-icon {
                position: relative;
                width: 26px;
                height: 26px;
                &--default, &--hover, &--active {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: $tr; }
                &--default {
                    opacity: 1; } }
            &-text {
                padding-left: 15px;
                word-break: break-all;
                font-family: $i-medium; }
            &-notify {
                margin-left: 18px;
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: $secondary;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px; }
            &-arrow {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 12px;
                width: 24px;
                height: 24px;
                margin: auto;
                display: flex;
                @include r($xs) {
                    display: none; } } }
        &__box {
            margin: 0 16px;
            padding: 27px 20px;
            border-top: 1px solid rgba(228, 233, 237, .4);
            @include r($md) {
                padding: 20px 16px;
                margin: 0; }
            @include r($xs) {
                padding: 16px;
                border: 0!important; }
            &:last-child {
                border-bottom: 1px solid rgba(228, 233, 237, .4); } }
        &__add-channel {
            .btn {
                width: 100%;
                font-size: 16px;
                padding: 13px 16px;
                &-icon {
                    border: 1px solid #fff;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    margin-right: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        width: 10px;
                        height: 10px; } } } }
        &__profile {
            display: flex;
            align-items: center;
            position: relative;
            &-avatar {
                display: none;
                background-color: $secondary;
                width: 48px;
                height: 48px;
                border-radius: 4px;
                margin-right: 12px; }
            &-content {
                width: calc(100% - 18px);
                color: $darkgray; }
            &-link {
                display: none;
                @include r($xs) {
                    display: block;
                    font-family: $i-semibold; } }
            &-name {
                color: $darkgray;
                &:hover {
                    color: $primary; }
                h4 {
                    margin-bottom: 2px; } }
            &-balance {
                font-family: $i-medium;
                @include r($xs) {
                    display: none; }
                &-value {
                    font-size: 16px;
                    display: inline-flex;
                    font-family: $i-semibold;
                    color: $secondary; }
                &-btn {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: bold; } }
            &-menu {
                align-self: flex-start;
                .dropdown {
                    position: static;
                    &.active {
                        .dropdown-btn {
                            transform: rotate(180deg); } }
                    &-btn span {
                        width: 18px;
                        height: 18px; }
                    &-box {
                        left: inherit;
                        right: -35px;
                        top: calc(100% + 5px);
                        @include r($md) {
                            right: -16px; } } } } }
        &__signout {
            padding: 0 30px;
            margin-top: 120px;
            @include r($xs) {
                margin: 0;
                position: absolute;
                left: 0;
                bottom: 30px;
                padding: 0 16px; }
            &-link {
                color: $gray;
                font-family: $i-medium;
                display: inline-flex;
                align-items: center; }
            &-icon {
                margin-right: 16px; } } }
    &-head {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-bottom: 22px;
        position: relative;
        margin-bottom: 40px;
        h1,h2,h3,h4,h5,h6 {
            margin: 0; }
        @include r($xs) {
            padding-bottom: 10px; }
        &:after {
            content: '';
            background-color: $secondary;
            left: 0;
            right: {}
            bottom: 0;
            position: absolute;
            height: 4px;
            width: 100%;
            border-radius: 12px;
            transition: $tr; }
        &--no-border {
            margin-bottom: 0;
            padding-bottom: 16px;
            &:after {
                content: none; } } }
    &-filter {
        position: relative;
        margin-bottom: 40px;
        &:after {
            content: '';
            background-color: $secondary;
            height: 4px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 12px;
            transition: $tr; }
        &.active {
            &:after {
                height: 0; } }
        &__items {
            display: flex;
            align-items: flex-end;
            @include r($xl) {
                flex-wrap: wrap; }
            @include r($mob) {
                margin: 0; } }
        &__inner {
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid $secondary;
            display: none;
            padding: 24px 0;
            @include r($mob) {
                padding: 24px 16px; } }
        &__item {
            padding: 0 16px;
            &:first-child {
                width: 33.333%;
                padding-left: 50px; }
            &:nth-child(2) {
                width: 25%; }
            &:nth-child(3) {
                width: calc(41.666% - 205px); }
            @include r($xl) {
                width: calc(33.333% - 32px)!important;
                margin: 0 16px 32px;
                padding: 0!important; }
            @include r($md) {
                width: calc(50% - 16px)!important;
                margin: 0 8px 16px;
                &:nth-last-child(2) {
                    margin-bottom: 0; } }
            @include r($mob) {
                width: 100%!important;
                margin: 0 0 12px; } }
        &__label {
            margin-bottom: 9px;
            display: flex;
            align-items: center;
            &-text {
                color: #1F232C;
                margin-right: 4px; } }
        &__btn {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            &.active {
                .pc-filter__btn {
                    &-icon {
                        &--default {
                            opacity: 0; }
                        &--active {
                            opacity: 1; } }
                    &-text {
                        color: $secondary; } } }
            &-icon {
                margin-right: 11px;
                position: relative;
                width: 24px;
                height: 24px;
                &--default, &--active {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: $tr; }
                &--default {
                    opacity: 1; } }
            &-text {
                transition: $tr;
                color: $gray;
                font-family: $i-medium; } }
        &__submit {
            width: 155px;
            @include r($xl) {
                width: 100%;
                margin: 0 16px; }
            @include r($md) {
                width: calc(50% - 16px);
                margin: 0 8px; }
            @include r($mob) {
                width: 100%;
                margin: 24px 0 0; } } }
    &-channels {}
    &-channel {
        border-radius: 12px;
        background-color: #fff;
        overflow: hidden;
        height: 100%;
        display: flex;
        flex-direction: column;
        transition: $tr;
        &:hover {
            box-shadow: 2px 4px 15px rgba(63, 68, 128, 0.2); }
        &__form {
            background-color: #fff;
            padding: 53px 32px;
            border-radius: 12px;
            height: 100%;
            position: relative;
            h3 {
                margin-bottom: 35px; }
            &-item {
                margin-bottom: 12px; }
            &-label {
                display: flex;
                align-items: center;
                margin-bottom: 5px; }
            &-text {
                margin-right: 5px; }
            &-submit {
                margin-top: 35px;
                .btn {
                    width: 100%;
                    margin-bottom: 10px; } } }
        &__head {
            padding: 16px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            position: relative;
            @include r($xs) {
                margin-bottom: 0;
                &:after {
                    content: '';
                    background-color: rgba(228, 233, 237, .8);
                    height: 1px;
                    width: calc(100% - 32px);
                    left: 16px;
                    right: 16px;
                    bottom: 0;
                    position: absolute; } }
            &:last-child {
                margin-bottom: 0; } }
        &__avatar {
            position: relative;
            width: 104px;
            height: 104px;
            margin-right: 16px;
            @include r($xs) {
                width: 72px;
                height: 72px;
                margin-right: 12px; }
            &-img {
                background-color: $primary;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                img {
                    border-radius: 50%; } } }
        &__status {
            position: absolute;
            left: 0;
            top: 0; }
        &__info {
            width: calc(100% - 120px);
            margin-top: 7px;
            @include r($xs) {
                width: calc(100% - 84px);
                margin-top: 0; } }
        &__pin {
            position: absolute;
            top: 16px;
            right: 10px;
            display: flex;
            align-items: center;
            justify-self: center;
            @include r($xs) {
                width: 26px;
                height: 26px;
                &>div, span {
                    width: 26px;
                    height: 26px; } }
            &.active {
                .pc-channel__pin {
                    &--active {
                        opacity: 1; }
                    &--default {
                        opacity: 0; } } }
            &--active {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: $tr; }
            &--default {
                opacity: 1;
                transition: $tr; } }
        &__name {
            font-size: 20px;
            margin-bottom: 4px;
            a {
                color: $darkgray;
                &:hover {
                    color: $secondary; } } }
        &__login {
            color: $gray;
            margin-bottom: 4px;
            display: inline-block; }
        &__service {
            font-size: 16px;
            color: $secondary;
            font-family: $i-semibold; }
        &__content {
            padding: 0 16px; }
        &__task {
            padding: 24px 0 20px;
            min-height: 123px;
            display: flex;
            border-top: 1px solid rgba(228, 233, 237, .8);
            &-media {
                width: 72px;
                margin-right: 16px; }
            &-content {
                width: calc(100% - 88px); }
            &-title {
                margin-bottom: 4px;
                a, span {
                    margin-left: 7px; } }
            &-funcs {
                display: flex;
                flex-direction: column;
                a {
                    padding: 4px 0; } }
            &-progress {
                margin-bottom: 4px; }
            &-controls {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: -10px;
                min-height: 40px; }
            &-control {
                margin: 0 10px 10px 0;
                transition: $tr;
                &:last-child {
                    margin-right: 0; }
                &:hover {
                    transform: scale(1.2); }
                &.disable {
                    pointer-events: none; } } }
        &__footer {
            background-color: $gray;
            color: #fff;
            padding: 8px 16px 16px;
            margin-top: auto; }
        &__controls {
            margin-bottom: -20px;
            &--mobile {
                display: none;
                @include r($xs) {
                    display: flex;
                    flex-wrap: wrap; } }
            &--desktop {
                display: flex;
                flex-wrap: wrap; } }
        &__control {
            display: inline-flex;
            margin: 0 20px 20px 0;
            position: relative;
            transition: $tr;
            @include r($xs) {
                span {
                    width: 32px;
                    height: 32px; } }
            &:last-child {
                margin-right: 0; }
            &:hover {
                .pc-channel__control {
                    &--default {
                        opacity: 0;
                        @include r($xs) {
                            opacity: 1; } }
                    &--hover {
                        opacity: 1;
                        transform: scale(1.2);
                        @include r($xs) {
                            opacity: 0; } } } }
            &.disable {
                opacity: 0.4;
                pointer-events: none; }
            &.active {
                .pc-channel__control {
                    &--default {
                        opacity: 0; }
                    &--active {
                        opacity: 1; } } }
            &--2 {
                opacity: 0.65;
                &:hover {
                    opacity: 1; } }
            &--default {
                transition: $tr;
                opacity: 1; }
            &--hover, &--active {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                opacity: 0;
                transition: $tr; } } }
    &-s-channel {
        &__head {
            .grid {
                margin: 0 -8px -16px; }
            [class^=grid-col--] {
                padding: 0 8px 16px; } }
        &__item {
            border-radius: 12px;
            background-color: #fff;
            box-shadow: 0px 6px 20px rgba(0, 5, 58, 0.05);
            display: flex;
            overflow: hidden;
            .pc-channel__head {
                &:after {
                    content: none; } }
            &-controls {
                width: 56px;
                background-color: $gray;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 24px 0;
                &--mobile {
                    display: none;
                    @include r($xs) {
                        display: block; } }
                .pc-channel {
                    &__controls {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 16px; }
                    &__control {
                        margin: 0 0 16px;
                        &:last-child {
                            margin-bottom: 0; } } } }
            &-content {
                width: calc(100% - 56px);
                padding: 10px 0;
                @include r($xs) {
                    width: 100%;
                    padding: 0; } }
            &-box {
                border-top: 1px solid rgba(228, 233, 237, .8);
                padding: 16px 0;
                margin: 0 16px; }
            &-info {
                &-row {
                    display: flex;
                    align-items: center;
                    margin-bottom: 6px;
                    &:last-child {
                        margin-bottom: 0; } }
                &-prop {
                    width: 50%; }
                &-value {
                    width: 50%;
                    padding-left: 6px; } } }
        &__comment {
            &-title {
                margin-bottom: 6px; }
            &-text {
                line-height: 1.5;
                position: relative;
                &.active {
                    &:after {
                        opacity: 0; }
                    .pc-s-channel__comment-text-btn {
                        span {
                            transform: rotate(-270deg); } } }
                @include r($xs) {
                    &:after {
                        content: '';
                        height: 70px;
                        background: linear-gradient(2.28deg, #FFFFFF 2.14%, rgba(255, 255, 255, 0) 190%);
                        position: absolute;
                        z-index: 1;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        transition: $tr; } }
                &-inner {
                    @include r($xs) {
                        height: 42px;
                        overflow: hidden;
                        transition: $tr; } }
                &-btn {
                    display: none;
                    position: relative;
                    z-index: 2;
                    cursor: pointer;
                    &.hidden {
                        display: none; }
                    @include r($xs) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 0;
                        margin-bottom: -16px; }
                    &-text {
                        font-family: $i-medium;
                        color: $secondary;
                        margin-right: 4px; }
                    span {
                        transform: rotate(-90deg);
                        width: 16px;
                        transition: $tr; } } } } }
    &-tasks {
        margin-bottom: -16px;
        @include r($xs) {
            margin-top: 16px; }
        &__title {
            display: none;
            @include r($xs) {
                display: block; } } }
    &-task {
        height: 100%;
        position: relative;
        &.active {
            z-index: 10;
            box-shadow: 0 6px 20px rgba(0, 5, 58, 0.15);
            .pc-task__footer-inner {
                box-shadow: 0 6px 20px rgba(0, 5, 58, 0.15); }
            .pc-task__btn {
                span {
                    transform: rotate(180deg); } } }
        &__drop {
            padding: 0 16px;
            display: none;
            background-color: #fff;
            &-box {
                padding: 16px 0 20px!important;
                &:first-child {
                    border-top: 1px solid rgba(228, 233, 237, .8); } }
            .pc-service-item {
                margin-bottom: 0;
                &__btns {
                    display: flex;
                    margin: 0 -8px;
                    .btn {
                        width: calc(50% - 16px);
                        margin: 0 8px;
                        &--white {
                            color: $dark; } } }
                &__tooltip {
                    margin: -3px 0 0;
                    span {
                        width: 16px;
                        height: 16px; } }
                &__title {
                    margin-right: 8px;
                    @include r($lg) {
                        padding: 0; }
                    &:before {
                        content: none; } }
                .irs-grid-text {
                    font-size: 12px; }
                &__slider-values {
                    font-size: 12px;
                    input {
                        font-size: 12px;
                        margin-right: 8px;
                        height: 38px;
                        border: 1px solid #e4e9ed;
                        border-radius: 4px;
                        color: #727b90;
                        &:focus {
                            border-color: #727b90; } } } } }
        &__inner {
            padding-bottom: 32px;
            border-radius: 12px;
            background-color: #fff;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            @include r($xs) {
                background-color: transparent;
                border-radius: 0;
                padding-bottom: 0; } }
        &__footer {
            height: 32px;
            margin-top: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            &-inner {
                border-radius: 0 0 12px 12px; }
            @include r($xs) {
                display: none; } }
        &__btn {
            background-color: $secondary;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 100%;
            border-radius: 0 0 12px 12px;
            span {
                transition: $tr; } }
        &__content {
            padding: 20px 14px;
            display: flex;
            @include r($xs) {
                padding: 0; }
            &--desktop {
                display: flex;
                @include r($xs) {
                    display: none; } }
            &--mobile {
                display: none;
                @include r($xs) {
                    display: block;
                    width: 100%; } }
            &-toggle {
                display: flex;
                align-items: center;
                background: #fff;
                box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
                border-radius: 4px;
                width: 100%;
                padding: 8px 16px;
                cursor: pointer;
                transition: $tr;
                &.active {
                    background-color: $secondary;
                    h4 {
                        color: #fff; }
                    .pc-task {
                        &__media {
                            &--default {
                                opacity: 0; }
                            &--active {
                                opacity: 1; } } } } }
            &-drop {
                display: none;
                padding: 10px 0; } }
        &__media {
            width: 72px;
            margin-right: 16px;
            position: relative;
            @include r($xs) {
                width: 48px;
                height: 48px;
                margin-right: 16px;
                span {
                    width: 100%; } }
            &--default {
                opacity: 1;
                transition: $tr; }
            &--active {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: $tr; } }
        &__info {
            width: calc(100% - 78px);
            padding-top: 5px;
            @include r($xs) {
                width: calc(100% - 64px);
                padding-top: 0;
                h4 {
                    margin: 0;
                    color: $secondary;
                    transition: $tr; } } } }
    &-h {
        &-tasks {
            margin-top: 56px;
            &__container {
                margin: 0 -12px; } }
        &-task {
            width: calc(33.333% - 24px);
            margin: 0 12px 24px;
            display: block;
            float: left;
            background-color: #fff;
            border-radius: 12px;
            box-shadow: 0px 6px 20px rgba(0, 5, 58, 0.05);
            padding: 16px;
            @include r($lg) {
                width: calc(50% - 24px); }
            @include r($xs) {
                width: calc(100% - 24px); }
            &__head {
                display: flex;
                padding-bottom: 8px;
                .pc-channel__task-controls {
                    @include r($md) {
                        display: none; } } }
            &__controls {
                display: none;
                @include r($md) {
                    display: block;
                    .pc-channel__task-controls {
                        border-top: 1px solid rgba(228, 233, 237, .8);
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        padding: 16px 0; } } }
            &__media {
                width: 72px;
                margin-right: 16px; }
            &__info {
                width: calc(100% - 88px);
                padding-top: 8px; }
            &__box {
                border-top: 1px solid rgba(228, 233, 237, .8);
                padding: 16px 0;
                &:last-child {
                    padding-bottom: 0; }
                h5 {
                    margin-bottom: 10px; } }
            &__details {
                &-item {
                    display: flex;
                    margin-bottom: 4px;
                    &:last-child {
                        margin-bottom: 0; } }
                &-prop {
                    width: 35%;
                    font-family: $i-medium; }
                &-value {
                    width: 65%;
                    padding-left: 10px; } } } }
    &-service {
        form {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;
            @include r($md) {
                margin: 0; } }
        &__title {
            color: $secondary;
            padding-bottom: 26px;
            margin-bottom: 26px;
            border-bottom: 1px solid rgba(228, 233, 237, .8); }
        &-content {
            width: calc(66.666% - 16px);
            margin: 0 8px;
            padding: 36px 32px;
            border-radius: 12px;
            background-color: #fff;
            align-self: flex-start;
            @include r($lg) {
                width: calc(100% - 282px);
                padding: 24px 16px; }
            @include r($md) {
                width: 100%;
                margin: 0 0 32px; } }
        &-side {
            width: calc(33.333% - 16px);
            margin: 0 8px;
            position: relative;
            @include r($lg) {
                width: 250px; }
            @include r($md) {
                width: 100%;
                margin: 0; } }
        &-sidebar {
            background-color: #fff;
            border-radius: 12px;
            padding: 24px;
            @include r($lg) {
                padding: 24px 16px; }
            @include r($md) {
                position: static!important;
                width: 100%!important; }
            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 24px; }
            &__avatar {
                width: 80px;
                height: 80px;
                margin-right: 16px;
                @include r($lg) {
                    width: 72px;
                    height: 72px;
                    margin-right: 12px; }
                &-img {
                    background-color: $primary;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%; } }
            &__info {
                width: calc(100% - 96px);
                @include r($lg) {
                    width: calc(100% - 84px); } }
            &__name {
                margin-bottom: 2px;
                a {
                    color: $darkgray;
                    &:hover {
                        color: $primary; } } }
            &__task {
                &-value {
                    color: $secondary;
                    margin-bottom: 0; }
                &-prop {
                    margin-bottom: 2px; } }
            &__content {
                margin-bottom: 16px; }
            &__item {
                padding: 16px 0;
                border-top: 1px solid rgba(228, 233, 237, .8);
                &:last-child {
                    border-bottom: 1px solid rgba(228, 233, 237, .8); }
                &-prop {
                    margin-bottom: 2px; }
                &-value {
                    margin-bottom: 0;
                    span {
                        font-size: 18px; } }
                &-result {
                    color: $secondary;
                    margin-bottom: 10px; }
                &-info {
                    span {
                        color: $primary; } } }
            &__submit {
                width: 100%;
                font-family: $i-semibold;
                margin-bottom: 8px; } }
        &-items {
            counter-reset: serviceItem; }
        &-item {
            counter-increment: serviceItem;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid rgba(228, 233, 237, .8);
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: 0; }
            &__hidden {
                display: none;
                &.active {
                    display: block; } }
            &__box {
                padding: 16px 0;
                border-top: 1px solid rgba(228, 233, 237, .8);
                &:first-child {
                    border: 0; }
                &:last-child {
                    padding-bottom: 0; }
                .pc-service-item {
                    &__head {
                        border-top: 1px solid rgba(228, 233, 237, .8);
                        padding-top: 24px; }
                    &__title {
                        &:before {
                            content: none; } } }
                &-label {
                    display: flex;
                    margin-bottom: 8px; }
                &-tooltip {
                    margin: -2px 0 0 3px; } }
            &__template {
                margin-top: 8px;
                font-family: $i-medium; }
            &__slider {
                margin-bottom: -5px;
                &-title {
                    font-family: $i-medium;
                    margin-right: 8px; }
                &-text {
                    font-family: $i-medium;
                    display: none;
                    @include r($lg) {
                        display: block;
                        margin-left: -8px; } }
                &-values {
                    display: flex;
                    align-items: baseline;
                    opacity: 0;
                    transition: $tr;
                    &.active {
                        opacity: 1; }
                    @include r($lg) {
                        margin: 12px 0 0; }
                    span {
                        margin-right: 4px;
                        font-family: $i-medium; }
                    input {
                        height: 38px;
                        border: 1px solid #e4e9ed;
                        border-radius: 4px;
                        color: #727b90;
                        font-family: $i-medium;
                        outline: 0;
                        padding: 0 8px;
                        margin-right: 12px;
                        text-align: center;
                        &:focus {
                            border-color: #727b90; }
                        &:last-child {
                            margin-right: 0; } } } }
            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                position: relative;
                @include r($lg) {
                    display: block; }
                &.done {
                    .pc-service-item__indicator-check {
                        opacity: 1; } } }
            &__indicator {
                width: 18px;
                height: 18px;
                position: relative;
                display: none;
                margin-right: 16px;
                @include r($lg) {
                    position: absolute;
                    left: 0;
                    margin: 0; }
                @include r($xs) {
                    display: none; }
                &-circle {
                    display: flex; }
                &-check {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: $tr; } }
            &__title {
                margin: 0 10px 0 0;
                @include r($lg) {
                    margin: 0;
                    padding: 0 36px 0 0; }
                @include r($xs) {
                    font-size: 14px!important;
                    font-family: $i-medium;
                    padding: 0 36px 0 0; }
                &:before {
                    content: counter(serviceItem) ".";
                    margin-right: 5px; } }
            &__tooltip {
                display: flex;
                margin-left: auto;
                @include r($lg) {
                    position: absolute;
                    right: 0;
                    top: 0; }
                span {
                    width: 24px;
                    height: 24px; } } } }
    &-profile {
        &__btn {
            position: absolute;
            width: 100%;
            display: block;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 0 0 12px 12px; }
        &-info {
            width: calc(32% - 24px);
            @include r($xl) {
                width: calc(50% - 24px); }
            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                &-content {
                    @include r($md) {
                        width: calc(100% - 84px); } } }
            &__avatar {
                width: 104px;
                height: 104px;
                margin-right: 16px;
                @include r($md) {
                    width: 72px;
                    height: 72px;
                    margin-right: 12px; }
                &-img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: $primary; } }
            &__content {
                border-top: 1px solid rgba(228, 233, 237, .8);
                border-bottom: 1px solid rgba(228, 233, 237, .8);
                padding: 16px 0; }
            &__footer {
                margin-top: 12px; }
            &__balance {
                color: $secondary;
                margin: 8px 0 24px; } }
        &-data {
            display: flex;
            flex-direction: column;
            width: calc(29% - 24px);
            @include r($xl) {
                width: calc(50% - 24px); }
            &__field {
                margin-bottom: 16px;
                &-label {
                    margin-bottom: 8px;
                    font-family: $i-medium;
                    display: block; } }
            &__submit {
                margin-top: auto; } }
        &-activity {
            width: calc(39% - 24px);
            padding-bottom: 32px!important;
            @include r($xl) {
                width: calc(100% - 24px); }
            @include r($md) {
                padding-bottom: 16px!important; }
            &__content {
                border-top: 1px solid rgba(228, 233, 237, .8);
                border-bottom: 1px solid rgba(228, 233, 237, .8);
                padding: 16px 0; }
            &__head {
                display: flex;
                margin-bottom: 11px; }
            &__task {
                width: 30%;
                @include r($xs) {
                    padding-right: 16px;
                    min-width: 100px; } }
            &__sum {
                width: 30%;
                @include r($xs) {
                    padding-right: 16px;
                    min-width: 100px; } }
            &__time {
                width: 40%;
                @include r($xs) {
                    padding-right: 16px;
                    min-width: 150px; } }
            &__item {
                display: flex;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0; }
                &.replenish {
                    background-color: $bg-green; } }
            &__items {
                overflow-y: auto;
                max-height: 300px; }
            &__ps {
                margin-top: 24px;
                color: $secondary;
                opacity: 0.6;
                text-align: center; } }
        &__tabs {
            &-items {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -12px -24px; }
            &-item {
                margin: 0 12px 24px;
                padding: 32px 32px 80px;
                background-color: #fff;
                border-radius: 12px;
                position: relative;
                transition: $tr;
                @include r($md) {
                    width: 100%!important;
                    padding: 16px 16px 70px;
                    display: none;
                    &.active {
                        display: block; } }
                &:hover {
                    box-shadow: 0px 6px 20px rgba(0, 5, 58, 0.05); } }
            &-btns {
                display: none;
                @include r($md) {
                    display: block;
                    margin: 0 -16px 24px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    -webkit-overflow-scrolling: touch;
                    position: relative; }
                &-inner {
                    padding: 0 16px;
                    position: relative;
                    display: inline-flex;
                    white-space: nowrap; } }
            &-btn {
                font-family: $i-medium;
                color: $gray;
                padding: 6px 0;
                margin-right: 18px;
                position: relative;
                z-index: 2;
                transition: $tr;
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: -18px;
                    bottom: 0;
                    width: calc(100% + 18px);
                    height: 2px;
                    background-color: $lightgray; }
                &:after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $secondary;
                    transform: scale(0);
                    transition: $tr; }
                &.active {
                    color: $secondary;
                    &:after {
                        transform: scale(1); } } } } }
    &-faq {
        &__search {
            background: #FFFFFF;
            box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
            border-radius: 4px;
            position: relative;
            height: 48px;
            overflow: hidden;
            margin-bottom: 16px;
            &-field {
                border: 0!important;
                outline: 0; }
            &-submit {
                padding: 0;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 48px;
                height: 49px;
                background-color: $secondary;
                font-size: 0;
                background-image: url("../images/search.svg");
                background-size: 24px;
                background-position: center;
                background-repeat: no-repeat;
                border: 0;
                outline: 0; } }
        &-menu {
            &__drop {
                padding: 6px 0;
                display: none;
                &-link {
                    display: flex;
                    padding: 6px 24px;
                    color: $darkgray;
                    font-family: $i-medium;
                    &:hover, &.active {
                        color: $secondary; } } }
            &__item {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0; } }
            &__link {
                width: 100%;
                display: flex;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
                border-radius: 4px;
                padding: 12px 20px;
                line-height: 1.3;
                transition: $tr;
                &:hover, &.active {
                    background-color: $secondary;
                    .pc-faq-menu {
                        &__icon {
                            &--default {
                                opacity: 0; }
                            &--active {
                                opacity: 1; } }
                        &__text {
                            color: #fff; } } }
                &.active {
                    background-color: #2BA1ED; } }
            &__icon {
                width: 24px;
                height: 24px;
                margin-right: 16px;
                position: relative;
                display: flex;
                &--default, &--active {
                    position: absolute;
                    left: 0;
                    top: 0;
                    display: flex;
                    transition: $tr; }
                &--default {
                    opacity: 1; }
                &--active {
                    opacity: 0; } }
            &__text {
                width: calc(100% - 40px);
                color: $darkgray;
                font-family: $i-medium;
                transition: $tr; } }
        &__main {
            padding: 40px 32px;
            border-radius: 4px;
            background-color: #fff;
            box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05); } }
    &-content {
        color: $darkgray;
        font-size: 16px;
        h1,h2,h3,h4,h5,h6 {
            color: $dark; }
        p {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0; } }
        img {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0; } }
        &-footer {
            border-top: 1px solid $lightgray;
            padding-top: 16px;
            display: flex;
            align-items: baseline;
            @include r($xs) {
                display: block; }
            &__title {
                margin-bottom: 0;
                color: $darkgray!important;
                @include r($xs) {
                    margin-bottom: 12px; } }
            &__links {
                margin-left: 24px;
                @include r($xs) {
                    margin-left: 0;
                    .btn {
                        width: 120px;
                        margin-right: 16px;
                        &--white {
                            color: $dark; } } }
                a {
                    font-family: $i-semibold;
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0; } } } } }
    &-support {
        &-wrap {
            display: flex;
            @include r($xl) {
                flex-direction: column; } }
        &__form {
            &-popup {
                display: block;
                position: static;
                width: calc(50% - 12px);
                margin-left: 24px;
                padding: 24px 32px;
                background-color: #fff;
                box-shadow: 0px 6px 20px rgba(0, 5, 58, 0.05);
                border-radius: 12px;
                visibility: visible;
                opacity: 1;
                z-index: 1;
                @include r($xl) {
                    margin-left: 0; }
                @include r($xs) {
                    position: fixed;
                    z-index: -1;
                    visibility: hidden;
                    &.active {
                        z-index: 30;
                        visibility: visible; }
                    &.animate {
                        .popup {
                            &-content {
                                opacity: 1; } } } }
                .popup {
                    &-title {
                        display: none; }
                    &-inner {
                        position: static;
                        padding: 0;
                        @include r($xs) {
                            position: absolute; } }
                    &-content {
                        opacity: 1;
                        transform: none;
                        max-width: 100%;
                        padding: 0;
                        background-color: transparent;
                        box-shadow: none;
                        @include r($xs) {
                            opacity: 0;
                            height: 100%;
                            background-color: $bg;
                            padding: 100px 16px 70px; } } }
                @include r($xl) {
                    width: 100%; } }
            .field {
                background-color: #fff; }
            &-fields {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -8px;
                @include r($mob) {
                    margin: 0; }
                .pc-support__form-field {
                    width: calc(50% - 16px);
                    margin: 0 8px 16px;
                    @include r($mob) {
                        width: 100%;
                        margin: 0 0 16px; } } }
            &-field {
                margin-bottom: 16px; }
            &-label {
                margin-bottom: 8px;
                font-family: $i-medium;
                display: block; }
            &-submit {
                margin-top: 24px; } }
        &__messages {
            width: calc(50% - 12px);
            background-color: #fff;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0px 6px 20px rgba(0, 5, 58, 0.05);
            @include r($xl) {
                width: 100%;
                margin-bottom: 24px; }
            @include r($xs) {
                background-color: transparent;
                width: calc(100% + 32px);
                margin: 0 -16px;
                border-radius: 0;
                box-shadow: none; }
            &-main {
                @include r($xs) {
                    border-radius: 12px;
                    background-color: #fff;
                    margin: 0 16px 32px; } }
            &-dropdown {
                display: none;
                @include r($xs) {
                    display: block;
                    margin: 0 16px 12px;
                    .dropdown {
                        &.active {
                            .dropdown-btn {
                                span {
                                    transform: rotate(-270deg); } } }
                        &-box {
                            width: 200px; }
                        &-btn {
                            padding: 12px 0;
                            display: inline-flex;
                            &__text {
                                margin-right: 8px; }
                            span {
                                transition: $tr;
                                transform: rotate(-90deg); } } } } }
            &-search {
                display: none;
                @include r($xs) {
                    display: block;
                    margin: 0 16px 16px; } }
            &-attach {
                padding: 24px 32px;
                border-top: 1px solid rgba(228, 233, 237, .8);
                border-bottom: 1px solid rgba(228, 233, 237, .8);
                @include r($xs) {
                    border: 0;
                    padding: 12px 16px 24px; }
                .btn {
                    display: none;
                    @include r($xs) {
                        display: block;
                        width: 100%; } }
                .addfile {
                    @include r($xs) {
                        margin-bottom: 12px; }
                    &-items {
                        &.active {
                            padding: 12px 0 0 0;
                            margin-bottom: 12px;
                            border-top: 1px solid rgba(228, 233, 237, .8);
                            border-bottom: 1px solid rgba(228, 233, 237, .8); } }
                    &-btn {
                        @include r($xs) {
                            display: flex; } }
                    &-icon, &-text {
                        @include r($xs) {
                            display: none; } } } }
            &-images {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 10px; }
            &-image {
                width: 33%;
                max-width: 200px;
                max-height: 140px;
                margin-right: 4px;
                text-align: center;
                &:first-child {
                    text-align: left; }
                &:last-child {
                    text-align: right;
                    margin-right: 0; }
                img {
                    border-radius: 4px;
                    min-width: 48px; }
                > span {
                    position: relative; }
                a.delete-btn {
                    position: absolute;
                    right: 1px;
                    top: 0;
                    background-color: $bg;
                    border-radius: 4px;
                    > span {
                        width: 32px; } } }
            &-head {
                padding: 20px 32px; }
            &-title {
                margin-bottom: 0; }
            &-back {
                border-bottom: 1px solid rgba(228, 233, 237, .8);
                padding: 14px 22px;
                display: flex;
                &-link {
                    display: inline-flex;
                    align-items: center; }
                &-text {
                    color: $secondary;
                    margin: 0 0 0 8px; } }
            &-info {
                padding: 16px 32px;
                @include r($xs) {
                    padding: 24px 16px 12px; } }
            &-content {
                &-title {
                    display: none;
                    @include r($xs) {
                        display: block;
                        padding: 0 16px; } } } }
        &__send {
            display: flex;
            height: 56px;
            @include r($xs) {
                margin-top: 30px; }
            &-field {
                margin: 0;
                background-color: $bg;
                border: 0!important;
                outline: 0;
                height: 100%;
                @include r($xs) {
                    border-radius: 0;
                    background-color: #fff; } }
            &-submit {
                width: 48px;
                height: 100%;
                background-image: url("../images/send.svg");
                background-repeat: no-repeat;
                background-size: 24px;
                background-position: center;
                border: 0;
                outline: 0;
                font-size: 0;
                @include r($xs) {
                    background-color: #fff;
                    border-radius: 0;
                    background-image: url("../images/send-blue.svg");
                    &:hover {
                        background-color: #fff; } } } } }
    &-messages {
        max-height: 500px;
        @include r($xs) {
            max-height: inherit;
            background-color: #fff; }
        .simplebar-track {
            top: 100px;
            bottom: 100px;
            right: 8px; }
        &--single {
            max-height: 250px;
            .simplebar-track {
                top: 16px;
                bottom: 16px; } }
        &__info {
            &-row {
                display: flex;
                margin-bottom: 12px;
                @include r($mob) {
                    display: block; }
                &:last-child {
                    margin-bottom: 0; }
                &--md {
                    font-family: $i-medium; } }
            &-prop {
                font-family: $i-medium;
                width: 33%;
                @include r($mob) {
                    width: 100%;
                    margin-bottom: 6px; } }
            &-value {
                width: 67%;
                padding-left: 10px;
                color: $gray;
                @include r($mob) {
                    padding-left: 0;
                    width: 100%; }
                span {
                    margin-right: 12px; } } }
        &__date {
            border-bottom: 1px solid rgba(228, 233, 237, .8);
            padding: 16px 32px;
            font-family: $i-medium;
            color: $gray; } }
    &-message {
        padding: 12px 32px;
        display: block;
        color: inherit;
        transition: $tr;
        @include r($xs) {
            padding: 12px 16px; }
        &:hover {
            background-color: rgba(46, 169, 249, .03); }
        &.primary {
            background-color: $bg-blue; }
        &.wait-answer {
            background-color: $bg-orange; }
        &--unread {
            background-color: rgba(46, 169, 249, .05);
            &:hover {
                background-color: rgba(46, 169, 249, .1); } }
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 6px; }
        &__name {
            margin-bottom: 0;
            span {
                font-family: $i-medium;
                margin-left: 2px; } }
        &__date {
            color: $darkgray; }
        &__text {
            color: $darkgray;
            max-width: 70%;
            display: block;
            @include r($xs) {
                max-width: 100%; } }
        &__content {
            padding-right: 40px;
            position: relative;
            width: 100%;
            display: block; }
        &__notify {
            width: 24px;
            height: 24px;
            background-color: $secondary;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: absolute;
            right: 0;
            top: 0; } }
    &-table {
        font-size: 14px;
        color: $darkgray;
        font-family: $i-medium;
        border: 1px solid $lightgray;
        border-collapse: collapse;
        width: 100%;
        &-scroll {
            padding-bottom: 10px;
            .simplebar-horizontal {
 } }                // margin-top: 8px
        tr {
            &:nth-child(even) {
                td {
                    background-color: #fff; } } }
        td, th {
            padding: 16px;
            text-align: left;
            border: 1px solid $lightgray;
            @include r($xs) {
                padding: 12px 16px; }
            p {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0; } } }
        th {
            font-family: $i-semibold;
            font-size: 16px;
            background-color: #fff;
            white-space: nowrap; } }
    &-api {
        font-size: 16px;
        &__token {
            margin-bottom: 40px;
            &--desktop {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @include r($xs) {
                    display: none; } }
            &--mobile {
                display: none;
                @include r($xs) {
                    display: block;
                    padding: 16px;
                    border: 2px solid $secondary;
                    border-radius: 12px;
                    .pc-api__token {
                        &-text {
                            margin: 0 0 8px; }
                        &-value {
                            margin: 0 0 16px; }
                        &-controls {
                            a {
                                margin-bottom: 12px;
                                width: 100%;
                                &:last-child {
                                    margin-bottom: 0; } } } } } }
            &-text {
                font-family: $i-semibold;
                margin-right: 40px; }
            &-value {
                white-space: nowrap;
                max-width: 100%;
                overflow-x: auto; }
            &-question {
                margin-bottom: 16px;
                font-family: $i-medium; }
            &-controls {
                span {
                    margin: 0 4px;
                    color: $gray; } } }
        &-box {
            margin-bottom: 35px;
            &:last-child {
                margin-bottom: 0; }
            h3, h4 {
                margin-bottom: 8px; } }
        &-tag {
            background: #fff;
            box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
            border-radius: 8px;
            color: $primary;
            padding: 4px 8px;
            font-size: 16px;
            font-family: $i-semibold;
            display: inline-flex; }
        &-items {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0; } }
        &-item {
            background: rgba(255, 255, 255, .3);
            box-shadow: 2px 4px 15px rgba(63, 68, 128, 0.07);
            border-radius: 4px;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0; }
            &__request {
                white-space: nowrap;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                padding: 0 16px 8px;
                margin: 0 -16px -8px;
                .pc-api-tag {
                    margin-right: 12px; } }
            &__head {
                padding: 18px 16px;
                color: $darkgray;
                background: #fff;
                box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
                border-radius: 4px;
                cursor: pointer;
                transition: $tr;
                &.active {
                    background-color: $gray;
                    color: #fff; }
                @include r($xs) {
                    padding: 18px 12px; }
                h5 {
                    margin: 0; } }
            &__content {
                padding: 24px 32px;
                display: none;
                @include r($xs) {
                    padding: 24px 16px; }
                h4 {
                    margin-bottom: 16px; } }
            &__details {
                display: flex;
                @include r($xs) {
                    display: block; }
                &-content {
                    flex-grow: 1;
                    padding-left: 12px;
                    @include r($xs) {
                        padding-left: 0;
                        margin-top: 8px; } }
                &-value {
                    color: $gray;
                    margin-bottom: 10px;
                    @include r($xs) {
                        margin-bottom: 5px; }
                    &:last-child {
                        margin-bottom: 0; } } } } }
    &-code {
        background: #fff;
        box-shadow: 2px 4px 4px rgba(63, 68, 128, 0.05);
        border-radius: 4px;
        padding: 24px 16px;
        color: $darkgray;
        line-height: 1.4;
        font-feature-settings: 'kern' off;
        font-family: $i-regular;
        margin-bottom: 16px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &:last-child {
            margin-bottom: 0; } }
    &-date {
        &__text {
            color: $darkgray;
            font-family: $i-medium;
            margin-bottom: 8px; }
        &__fields {
            display: flex; }
        &__item {
            width: calc(100% - 127px);
            margin-right: 8px;
            @include r($xs) {
                width: calc(100% - 103px); }
            .field {
                height: 40px;
                background-image: url("../images/calendar.svg");
                background-repeat: no-repeat;
                background-position: calc(100% - 8px) center;
                color: $dark;
                @include r($xs) {
                    padding: 0 8px; }
                &::placeholder {
                    color: $dark; } } }
        &__time {
            display: flex;
            &-item {
                width: 60px;
                position: relative;
                z-index: 1;
                &.active {
                    z-index: 2; }
                @include r($xs) {
                    width: 48px; } }
            &-hour {
                .select2-container {
                    .select2-selection {
                        border-radius: 4px 0 0 4px; } } }
            &-minute {
                margin-left: -1px;
                .select2-container {
                    .select2-selection {
                        border-radius: 0 4px 4px 0; } } }
            .select2-container {
                .select2-selection {
                    height: 40px;
                    .select2-selection {
                        &__rendered {
                            padding: 0 12px;
                            line-height: 40px;
                            font-family: $i-medium;
                            color: $darkgray;
                            @include r($xs) {
                                padding: 0 8px; } }
                        &__arrow {
                            right: 3px; } } } } } }
    &-fixed-btn {
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        @include r($xs) {
            display: block; }
        .btn {
            width: 100%;
            border-radius: 0; } }
    &-alert-container {
        position: fixed;
        right: 16px;
        bottom: 16px;
        z-index: 100;
        .alert {
            max-width: 500px;
            border: 1px solid;
            border-radius: 4px;
            padding: 10px 32px 10px 16px;
            margin-top: 12px;
            box-shadow: 0 6px 20px rgba(0, 5, 58, 0.05);
            position: relative;
            .close {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: 21px;
                font-weight: 700;
                line-height: 17px;
                color: #000;
                text-shadow: 0 1px 0 #fff;
                filter: alpha(opacity=20);
                opacity: .5;
                margin-left: 12px;
                outline: none;
                &:hover {
                    opacity: 1; } }
            button.close {
                padding: 0;
                cursor: pointer;
                background: 0 0;
                border: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none; }
            &__success {
                color: $green;
                background-color: $bg-green;
                border-color: $green;
                .close {
                    color: $green; } }
            &__info {
                color: $primary;
                background-color: $bg-blue;
                border-color: $primary;
                .close {
                    color: $primary; } }
            &__warning {
                color: $orange;
                background-color: $bg-orange;
                border-color: $orange;
                .close {
                    color: $orange; } }
            &__danger {
                color: $error;
                background-color: $bg-red;
                border-color: $error;
                .close {
                    color: $error; } } } } }
